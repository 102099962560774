import { helpIcon, dimCell, formData as formData_1 } from "./Helpers.js";
import { FSharpMap__TryFind } from "../fable_modules/fable-library-js.4.20.0/Map.js";
import { Lit_refValue_Z4DF72B44, LitHelpers_html } from "../fable_modules/Fable.Lit.1.4.2/Lit.fs.js";
import { fmt } from "../fable_modules/fable-library-js.4.20.0/String.js";

export function renderContainer(model, containerUnitRef, containerWUnitRef) {
    const formData = formData_1(model);
    let defaultxcontainerunit;
    const matchValue = FSharpMap__TryFind(formData, "xcontainer-unit");
    defaultxcontainerunit = ((matchValue != null) ? matchValue : "10");
    let defaultwcontainerunit;
    const matchValue_1 = FSharpMap__TryFind(formData, "wcontainer-unit");
    defaultwcontainerunit = ((matchValue_1 != null) ? matchValue_1 : "1000");
    let defaultFrontSpace;
    const matchValue_2 = FSharpMap__TryFind(formData, "front-space");
    defaultFrontSpace = ((matchValue_2 != null) ? matchValue_2 : "0");
    let defaultBackSpace;
    const matchValue_3 = FSharpMap__TryFind(formData, "back-space");
    defaultBackSpace = ((matchValue_3 != null) ? matchValue_3 : "0");
    let defaultLeftSpace;
    const matchValue_4 = FSharpMap__TryFind(formData, "left-space");
    defaultLeftSpace = ((matchValue_4 != null) ? matchValue_4 : "0");
    let defaultRightSpace;
    const matchValue_5 = FSharpMap__TryFind(formData, "right-space");
    defaultRightSpace = ((matchValue_5 != null) ? matchValue_5 : "0");
    let defaultTopSpace;
    const matchValue_6 = FSharpMap__TryFind(formData, "top-space");
    defaultTopSpace = ((matchValue_6 != null) ? matchValue_6 : "0");
    const containerDimCell = (minValue, maxValue, defaultValue, name, index) => dimCell(formData, "container", minValue, maxValue, defaultValue, name, index);
    return LitHelpers_html(fmt`
     <fieldset class="container item">
        <legend>Container dimensions</legend>
        <bindrake-selectcontainer></bindrake-selectcontainer>
        <table aria-label="Container dimensions">
            <thead>
                <tr>
                    <th> 
                    <div> 
                        Length 
                        <span> ${helpIcon}  
                            <tool-tip inert role="tooltip" tip-position="bottom">
                                Length of the container.
                                The range is 1 to 20 000. It also accepts 2 decimals.
                            </tool-tip> 
                        </span>
                    </div> 
                    </th>
                    <th>
                    <div> 
                        Width 
                        <span> ${helpIcon}  
                            <tool-tip inert role="tooltip" tip-position="bottom">
                                Width of the container.
                                The range is 1 to 20 000. It also accepts 2 decimals.
                            </tool-tip> 
                        </span>
                    </div> 
                    </th>
                    <th>
                    <div> 
                        Height 
                        <span> ${helpIcon}  
                            <tool-tip inert role="tooltip" tip-position="bottom">
                                Height of the container.
                                The range is 1 to 20 000. It also accepts 2 decimals.
                            </tool-tip> 
                        </span>
                    </div> 
                    </th>
                    <th>
                    <div> 
                        Max Weight 
                        <span> ${helpIcon}  
                            <tool-tip inert role="tooltip" tip-position="bottom">
                                Max weight capacity of the container.
                                The range is 1 to 100 000. It also accepts 2 decimals.
                                You can leave default as 0 if not needed.
                            </tool-tip> 
                        </span>
                    </div> 
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    ${containerDimCell("0.01", "20000", undefined, "length", "")}
                    ${containerDimCell("0.01", "20000", undefined, "width", "")}
                    ${containerDimCell("0.01", "20000", undefined, "height", "")}
                    ${containerDimCell("0", "200000", 0, "maxWeight", "")}
                </tr>
                <tr>
                    <td colspan="3">
                    <select ${Lit_refValue_Z4DF72B44(containerUnitRef)} title="Container unit" name='xcontainer-unit'>
                        <option  ?selected=${defaultxcontainerunit === "1"} value="1">Container units: mm</option>
                        <option  ?selected=${defaultxcontainerunit === "10"} value="10">Container units: cm</option>
                        <option  ?selected=${defaultxcontainerunit === "1000"} value="1000">Container units: m</option>
                        <option  ?selected=${defaultxcontainerunit === "ft"} value="ft">Container units: ft</option>
                        <option  ?selected=${defaultxcontainerunit === "in"} value="in">Container units: in</option>
                    </select>
                    </td>
                    <td>
                    <select ${Lit_refValue_Z4DF72B44(containerWUnitRef)} title="Container weight unit" name='wcontainer-unit'>
                        <option  ?selected=${defaultwcontainerunit === "1"} value="1">gr</option>
                        <option  ?selected=${defaultwcontainerunit === "1000"} value="1000">kg</option>
                        <option  ?selected=${defaultwcontainerunit === "1000000"} value="1000000">metric tonnes</option>
                        <option  ?selected=${defaultwcontainerunit === "oz"} value="oz">oz</option>
                        <option  ?selected=${defaultwcontainerunit === "lbs"} value="lbs">lbs</option>
                    </select>
                    </td>
                </tr>
            </tbody>
        </table>
        <details>
            <summary> Extra treshold & spaces  
                <span> ${helpIcon}  
                    <tool-tip inert role="tooltip" tip-position="right">
                        Extra tresholds & spaces are used to add extra space to the container, for easier loading.
                        The range is 0 to 10 000. It also accepts 2 decimals.
                    </tool-tip> 
                </span>
            </summary>
            <table aria-label="extra tresholds">
                <thead>
                    <tr>
                        <th> 
                            Front Space
                        </th>
                        <th>
                            Back Space 
                        </th>
                        <th>
                            Left Space 
                        </th>
                        <th>
                            Right Space 
                        </th>
                        <th>
                            Top Space 
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <input type="number" name="front-space" min="0" max="10000" step="0.01" .value=${defaultFrontSpace} />
                        </td>
                        <td>
                            <input type="number" name="back-space" min="0" max="10000" step="0.01" .value=${defaultBackSpace} />
                        </td>
                        <td>
                            <input type="number" name="left-space" min="0" max="10000" step="0.01" .value=${defaultLeftSpace} />
                        </td>
                        <td>
                            <input type="number" name="right-space" min="0" max="10000" step="0.01" .value=${defaultRightSpace} />
                        </td>
                        <td>
                            <input type="number" name="top-space" min="0" max="10000" step="0.01" .value=${defaultTopSpace} />
                        </td>
                    </tr>
                </tbody>
            </table>

        </details>
    </fieldset>
`);
}

