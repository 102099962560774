import { toString, Record, Union } from "../fable_modules/fable-library-js.4.20.0/Types.js";
import { record_type, bool_type, union_type, option_type, string_type } from "../fable_modules/fable-library-js.4.20.0/Reflection.js";
import { Authentication_Email_TryCreate_Z721C83C5, Authentication_VerificationCode_$reflection, Authentication_Email_$reflection } from "../Domain/Model.js";
import { join } from "../fable_modules/fable-library-js.4.20.0/String.js";
import { map } from "../fable_modules/fable-library-js.4.20.0/List.js";
import { defaultOf } from "../fable_modules/fable-library-js.4.20.0/Util.js";
import { defaultArgWith, value } from "../fable_modules/fable-library-js.4.20.0/Option.js";

export class State extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["NotLoggedIn", "Email", "Verify", "LoggedIn", "LoginCompleted"];
    }
}

export function State_$reflection() {
    return union_type("BestFitBox.MVU.Login.State", [], State, () => [[], [], [["Item", option_type(string_type)]], [["Item", Authentication_Email_$reflection()]], [["Item", Authentication_Email_$reflection()]]]);
}

export class Model extends Record {
    constructor(State, Email, IsBusy, FirstRun) {
        super();
        this.State = State;
        this.Email = Email;
        this.IsBusy = IsBusy;
        this.FirstRun = FirstRun;
    }
}

export function Model_$reflection() {
    return record_type("BestFitBox.MVU.Login.Model", [], Model, () => [["State", State_$reflection()], ["Email", option_type(Authentication_Email_$reflection())], ["IsBusy", bool_type], ["FirstRun", bool_type]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["EmailRequested", "VerifyRequested", "VerificationCodeSubmitted", "Cancelled", "Completed", "EmailFailed", "VerificationFailed", "VerificationSuccessful", "EmailSuccess", "LogoutRequested", "LogoutSuccess", "LogoutError"];
    }
}

export function Msg_$reflection() {
    return union_type("BestFitBox.MVU.Login.Msg", [], Msg, () => [[], [["Item", option_type(Authentication_Email_$reflection())]], [["Item", Authentication_VerificationCode_$reflection()]], [], [], [["Item", string_type]], [], [], [], [], [], []]);
}

export class Order extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["None", "Login", "Verify", "Logout", "ShowError"];
    }
}

export function Order_$reflection() {
    return union_type("BestFitBox.MVU.Login.Order", [], Order, () => [[], [["Item", Authentication_Email_$reflection()]], [["Item1", Authentication_Email_$reflection()], ["Item2", Authentication_VerificationCode_$reflection()]], [["Item", Authentication_Email_$reflection()]], [["Item", string_type]]]);
}

export function init(user, unitVar) {
    let matchResult;
    if (user === "") {
        matchResult = 0;
    }
    else if (user === defaultOf()) {
        matchResult = 0;
    }
    else {
        matchResult = 1;
    }
    switch (matchResult) {
        case 0:
            return [new Model(new State(0, []), undefined, false, true), new Order(0, [])];
        default: {
            let userId;
            const e_1 = Authentication_Email_TryCreate_Z721C83C5(user);
            if (e_1.tag === 1) {
                throw new Error(join(", ", map((x_2) => {
                    let copyOfStruct = x_2;
                    return toString(copyOfStruct);
                }, e_1.fields[0])));
            }
            else {
                userId = e_1.fields[0];
            }
            return [new Model(new State(4, [userId]), userId, false, true), new Order(0, [])];
        }
    }
}

export function update(msg_mut, model_mut) {
    update:
    while (true) {
        const msg = msg_mut, model = model_mut;
        const model_1 = model.FirstRun ? (new Model(model.State, model.Email, model.IsBusy, false)) : model;
        switch (msg.tag) {
            case 4:
                return [new Model(new State(4, [value(model_1.Email)]), model_1.Email, model_1.IsBusy, model_1.FirstRun), new Order(0, [])];
            case 0:
                return [new Model(new State(1, []), model_1.Email, false, model_1.FirstRun), new Order(0, [])];
            case 1: {
                const userId_1 = defaultArgWith(msg.fields[0], () => value(model_1.Email));
                return [new Model(model_1.State, userId_1, true, model_1.FirstRun), new Order(1, [userId_1])];
            }
            case 8:
                return [new Model(new State(2, [undefined]), model_1.Email, false, model_1.FirstRun), new Order(0, [])];
            case 2:
                return [new Model(model_1.State, model_1.Email, true, model_1.FirstRun), new Order(2, [value(model_1.Email), msg.fields[0]])];
            case 6:
                return [new Model(new State(2, ["Invalid verification code"]), model_1.Email, false, model_1.FirstRun), new Order(0, [])];
            case 5:
                return [new Model(new State(0, []), model_1.Email, false, model_1.FirstRun), new Order(4, [msg.fields[0]])];
            case 7:
                return [new Model(new State(3, [value(model_1.Email)]), model_1.Email, false, model_1.FirstRun), new Order(0, [])];
            case 9:
                return [new Model(model_1.State, model_1.Email, true, model_1.FirstRun), new Order(3, [value(model_1.Email)])];
            case 10:
                return [new Model(new State(0, []), undefined, false, model_1.FirstRun), new Order(0, [])];
            case 11:
                return [new Model(model_1.State, model_1.Email, false, model_1.FirstRun), new Order(0, [])];
            default: {
                const matchValue = model_1.State;
                switch (matchValue.tag) {
                    case 3:
                    case 4: {
                        msg_mut = (new Msg(4, []));
                        model_mut = model_1;
                        continue update;
                    }
                    default:
                        return [new Model(new State(0, []), model_1.Email, model_1.IsBusy, model_1.FirstRun), new Order(0, [])];
                }
            }
        }
        break;
    }
}

