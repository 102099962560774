import { cancel, startImmediate, isCancellationRequested, sleep, createCancellationToken } from "../fable_modules/fable-library-js.4.20.0/Async.js";
import { singleton } from "../fable_modules/fable-library-js.4.20.0/AsyncBuilder.js";
import { update, init, Msg } from "../MVU/Components/CalculateButton.js";
import { uncurry2, equals, defaultOf } from "../fable_modules/fable-library-js.4.20.0/Util.js";
import { HookComponentAttribute } from "../fable_modules/Fable.Lit.1.4.2/Hook.fs.js";
import { LitHelpers_html } from "../fable_modules/Fable.Lit.1.4.2/Lit.fs.js";
import { fmt } from "../fable_modules/fable-library-js.4.20.0/String.js";
import { item } from "../fable_modules/fable-library-js.4.20.0/Array.js";
import { FormElementAttribute } from "./CustomLit.js";
import { Lit_HookContext__HookContext_useEffectOnChange_31A5188A, Hook_getContext_343DAFF1, Hook_useHmr_ZF11B4D8 } from "../fable_modules/Fable.Lit.1.4.2/Hook.fs.js";
import { Prop_Of_Z4A92C983 } from "../fable_modules/Fable.Lit.1.4.2/LitElement.fs.js";
import { parse } from "../fable_modules/fable-library-js.4.20.0/Int32.js";
import { parse as parse_1 } from "../fable_modules/fable-library-js.4.20.0/Boolean.js";
import { LitElmishExtensions_useElmish } from "../fable_modules/Fable.Lit.Elmish.1.4.0/Lit.Elmish.fs.js";
import { Program_mkHiddenProgramWithOrderExecute } from "../ElmishOrder.js";

const hmr = undefined;

export function execute(host, order, dispatch) {
    switch (order.tag) {
        case 1: {
            const cts = createCancellationToken();
            host.cts = cts;
            const loop = () => singleton.Delay(() => singleton.Bind(sleep(1000), () => {
                dispatch(new Msg(1, []));
                return !isCancellationRequested(cts) ? singleton.ReturnFrom(loop()) : singleton.Return(undefined);
            }));
            startImmediate(loop());
            break;
        }
        case 2: {
            cancel(host.cts);
            host.cts = defaultOf();
            break;
        }
        default:
            undefined;
    }
}

export const view = (new HookComponentAttribute()).Decorate(function (host, model, dispatch) {
    return LitHelpers_html(fmt`
    <link rel="stylesheet" href="/css/base.css?v=202407121659">
    ${model.IsBusy ? LitHelpers_html(fmt`
                <div class=spinner>
                    <div class="opposites">
                        <div class="opposites bl"></div>
                        <div class="opposites tr"></div>
                        <div class="opposites br"></div>
                        <div class="opposites tl"></div>
                    </div>
                </div>
                <p class="timer"> Max time: ${model.TimerCurrent} </p>
                `) : LitHelpers_html(fmt`
            <button type="button" class=calculate  @click='${(_arg) => {
        host.internals.form.requestSubmit();
    }}'>
                    <img src="/assets/icons/favicon-rounded.svg">
                    Calculate
                </button>
                `)}
    `);
});

export function changed() {
    const x = item(0, arguments);
    const y = item(1, arguments);
    if (equals(y, void 0)) {
        return false;
    }
    else {
        return !equals(x, y);
    }
}

export const calculateButton = (new FormElementAttribute("bindrake-calculate-button")).Decorate(function () {
    Hook_useHmr_ZF11B4D8(hmr, this);
    let p;
    const timer = Prop_Of_Z4A92C983(240, "timer", uncurry2(changed), (x) => parse(x, 511, false, 32));
    p = {
        isBusy: Prop_Of_Z4A92C983(false, "isbusy", uncurry2(changed), parse_1),
        timer: timer,
    };
    const patternInput = (this).init((arg) => {
        let a;
        const config = arg;
        config.useShadowDom = true;
        a = (config.props = p);
        return Promise.resolve(undefined);
    });
    const props = patternInput[1];
    const host = patternInput[0];
    host.click = ((_arg) => (host.internals.form.requestSubmit()));
    if (!host.internals) {
        host.internals = (host.attachInternals());
    }
    const patternInput_1 = LitElmishExtensions_useElmish(Hook_getContext_343DAFF1(this), () => {
        let isBusy, timer_1;
        return Program_mkHiddenProgramWithOrderExecute((isBusy = (props.isBusy), (timer_1 = ((props.timer) | 0), () => init(isBusy, timer_1, undefined))), update, (order, dispatch) => {
            execute(host, order, dispatch);
        });
    });
    const dispatch_1 = patternInput_1[1];
    const value_3 = props.isBusy;
    Lit_HookContext__HookContext_useEffectOnChange_31A5188A(Hook_getContext_343DAFF1(this), value_3, (_arg_1) => {
        dispatch_1(new Msg(0, [props.isBusy]));
    });
    return view(host, patternInput_1[0], dispatch_1);
});

export function register() {
}

