import { Union, Record } from "../fable_modules/fable-library-js.4.20.0/Types.js";
import { Calculation_Container_$reflection, Calculation_ItemPut_$reflection } from "../Domain/Model.js";
import { union_type, int32_type, class_type, string_type, int64_type, option_type, record_type, list_type } from "../fable_modules/fable-library-js.4.20.0/Reflection.js";
import { item } from "../fable_modules/fable-library-js.4.20.0/Array.js";
import { split } from "../fable_modules/fable-library-js.4.20.0/String.js";
import { filter, mapIndexed, find } from "../fable_modules/fable-library-js.4.20.0/Seq.js";
import { value } from "../fable_modules/fable-library-js.4.20.0/Option.js";
import { create, match } from "../fable_modules/fable-library-js.4.20.0/RegExp.js";
import { parse } from "../fable_modules/fable-library-js.4.20.0/Int32.js";

export class CanvasOutput extends Record {
    constructor(ItemsPut, Container) {
        super();
        this.ItemsPut = ItemsPut;
        this.Container = Container;
    }
}

export function CanvasOutput_$reflection() {
    return record_type("BestFitBox.MVU.Canvas.CanvasOutput", [], CanvasOutput, () => [["ItemsPut", list_type(Calculation_ItemPut_$reflection())], ["Container", Calculation_Container_$reflection()]]);
}

export class Model extends Record {
    constructor(OutputRow, SelectedItem, Muliplier, FormData, FormKey, FormIndex) {
        super();
        this.OutputRow = OutputRow;
        this.SelectedItem = SelectedItem;
        this.Muliplier = Muliplier;
        this.FormData = FormData;
        this.FormKey = FormKey;
        this.FormIndex = FormIndex;
    }
}

export function Model_$reflection() {
    return record_type("BestFitBox.MVU.Canvas.Model", [], Model, () => [["OutputRow", option_type(CanvasOutput_$reflection())], ["SelectedItem", option_type(Calculation_ItemPut_$reflection())], ["Muliplier", option_type(int64_type)], ["FormData", option_type(class_type("Microsoft.FSharp.Collections.FSharpMap`2", [string_type, string_type]))], ["FormKey", option_type(int32_type)], ["FormIndex", option_type(int32_type)]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["OutputSet", "ClearOutput", "SetItemPut"];
    }
}

export function Msg_$reflection() {
    return union_type("BestFitBox.MVU.Canvas.Msg", [], Msg, () => [[["Item1", CanvasOutput_$reflection()], ["Item2", class_type("Microsoft.FSharp.Collections.FSharpMap`2", [string_type, string_type])], ["Item3", int64_type]], [], [["Item", Calculation_ItemPut_$reflection()]]]);
}

export class Order extends Union {
    constructor() {
        super();
        this.tag = 0;
        this.fields = [];
    }
    cases() {
        return ["NoOrder"];
    }
}

export function Order_$reflection() {
    return union_type("BestFitBox.MVU.Canvas.Order", [], Order, () => [[]]);
}

export function init() {
    return [new Model(undefined, undefined, undefined, undefined, undefined, undefined), new Order()];
}

export function update(msg, model) {
    switch (msg.tag) {
        case 1:
            return [new Model(undefined, undefined, undefined, undefined, undefined, undefined), new Order()];
        case 2: {
            const itemPut = msg.fields[0];
            const tag = item(0, split(itemPut.Item.Tag, ["?"], undefined, 0));
            const patternInput = find((tupledArg) => (tupledArg[1][1] === tag), mapIndexed((i, kvp_1) => [i, kvp_1], filter((kvp) => (kvp[0].indexOf("color") >= 0), value(model.FormData))));
            const matc = match(create("-(\\d+)-"), patternInput[1][0]);
            return [new Model(model.OutputRow, itemPut, model.Muliplier, model.FormData, parse(matc[1] || "", 511, false, 32), patternInput[0]), new Order()];
        }
        default:
            return [new Model(msg.fields[0], model.SelectedItem, msg.fields[2], msg.fields[1], model.FormKey, model.FormIndex), new Order()];
    }
}

