import { Remoting_buildProxy_64DC51C } from "../fable_modules/Fable.Remoting.Client.7.32.0/Remoting.fs.js";
import { RemotingModule_createApi, RemotingModule_withRouteBuilder } from "../fable_modules/Fable.Remoting.Client.7.32.0/Remoting.fs.js";
import { Calculation_$reflection, Route_builder } from "../Domain/API.js";
import { LitElementAttribute, Lit_LitElement__LitElement_dispatchCustomEvent_1EA4E0D4 } from "../fable_modules/Fable.Lit.1.4.2/LitElement.fs.js";
import { startImmediate } from "../fable_modules/fable-library-js.4.20.0/Async.js";
import { singleton } from "../fable_modules/fable-library-js.4.20.0/AsyncBuilder.js";
import { tail, head, isEmpty } from "../fable_modules/fable-library-js.4.20.0/List.js";
import { update, init, Msg } from "../MVU/FillFromExcel.js";
import { FSharpMap__get_Item, FSharpMap__get_Count } from "../fable_modules/fable-library-js.4.20.0/Map.js";
import { fmt, substring } from "../fable_modules/fable-library-js.4.20.0/String.js";
import { Hook_createDisposable_3A5B6456, HookComponentAttribute } from "../fable_modules/Fable.Lit.1.4.2/Hook.fs.js";
import { Hook_getContext_343DAFF1, Hook_useHmr_ZF11B4D8 } from "../fable_modules/Fable.Lit.1.4.2/Hook.fs.js";
import { LitHelpers_html } from "../fable_modules/Fable.Lit.1.4.2/Lit.fs.js";
import { cleanDSL } from "../ViewFunctions.js";
import { defaultOf } from "../fable_modules/fable-library-js.4.20.0/Util.js";
import { Program_mkHiddenProgramWithOrderExecute } from "../ElmishOrder.js";
import { LitElmishExtensions_useElmish } from "../fable_modules/Fable.Lit.Elmish.1.4.0/Lit.Elmish.fs.js";

const hmr = undefined;

export const Server_api = Remoting_buildProxy_64DC51C(RemotingModule_withRouteBuilder(Route_builder, RemotingModule_createApi()), Calculation_$reflection());

export function execute(host, order, dispatch) {
    switch (order.tag) {
        case 2: {
            Lit_LitElement__LitElement_dispatchCustomEvent_1EA4E0D4(host, "loginRequested", undefined, true, true);
            break;
        }
        case 1: {
            startImmediate(singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(Server_api.UploadAI(order.fields[0]), (_arg) => {
                let message, message_1;
                const res = _arg;
                return singleton.Combine((res.tag === 1) ? (!isEmpty(res.fields[0]) ? ((head(res.fields[0]).tag === 3) ? (isEmpty(tail(res.fields[0])) ? ((Lit_LitElement__LitElement_dispatchCustomEvent_1EA4E0D4(host, "buyCreditsRequested", head(res.fields[0]).fields[0], true, true, true), (dispatch(new Msg(1, [])), singleton.Zero()))) : ((dispatch(new Msg(6, [])), singleton.Zero()))) : ((dispatch(new Msg(6, [])), singleton.Zero()))) : ((dispatch(new Msg(6, [])), singleton.Zero()))) : (((FSharpMap__get_Count(res.fields[0][0]) === 0) ? true : (FSharpMap__get_Item(res.fields[0][0], "custom-message").length > 0)) ? ((res.fields[0][2] === "") ? ((navigator.sendBeacon("/log-error", "Unable to parse your text. Please check the format and try again."), (window.showError("Unable to parse your text. Please check the format and try again."), singleton.Zero()))) : ((message = FSharpMap__get_Item(res.fields[0][0], "custom-message"), (message_1 = ((message.indexOf("Your query was") >= 0) ? substring(message, 0, message.indexOf("Your query was")) : message), (window.showError(message_1), (dispatch(new Msg(1, [])), singleton.Zero())))))) : ((Lit_LitElement__LitElement_dispatchCustomEvent_1EA4E0D4(host, "excelUploaded", [res.fields[0][0], res.fields[0][1]], true, true, true), singleton.Zero()))), singleton.Delay(() => singleton.Return(undefined)));
            })), (_arg_1) => {
                const e = _arg_1;
                navigator.sendBeacon("/log-error", e.message);
                return singleton.Combine((e.message.indexOf("429") >= 0) ? ((window.showError("Too frequent tries. Please wait a while and try again."), singleton.Zero())) : ((window.showError("An error occured. Please try again."), singleton.Zero())), singleton.Delay(() => {
                    dispatch(new Msg(6, []));
                    return singleton.Zero();
                }));
            })));
            break;
        }
        default:
            undefined;
    }
}

export const view = (new HookComponentAttribute()).Decorate(function (host, model, dispatch) {
    Hook_useHmr_ZF11B4D8(hmr, this);
    Hook_getContext_343DAFF1(this).useEffectOnce(() => {
        const loggedIn = (e) => {
            dispatch(new Msg(4, []));
        };
        const loggedOut = (e_1) => {
            dispatch(new Msg(5, []));
        };
        const viewHandler = (e_2) => {
            dispatch(new Msg(1, []));
        };
        const aiTextHandler = (e_3) => {
            const customEvent = e_3;
            const aiText = customEvent.detail;
            host.shadowRoot.querySelector("textarea").value = aiText;
        };
        document.addEventListener("loggedIn", loggedIn);
        document.addEventListener("loggedOut", loggedOut);
        document.addEventListener("viewIn3d", viewHandler);
        document.addEventListener("errorOccured", viewHandler);
        document.addEventListener("aiTextChanged", aiTextHandler);
        document.addEventListener("buyCreditsRequested", viewHandler);
        return Hook_createDisposable_3A5B6456(() => {
            document.removeEventListener("loggedIn", loggedIn);
            document.removeEventListener("loggedOut", loggedOut);
            document.removeEventListener("viewIn3d", viewHandler);
            document.removeEventListener("errorOccured", viewHandler);
            document.removeEventListener("loggedOut", aiTextHandler);
            document.removeEventListener("buyCreditsRequested", viewHandler);
        });
    });
    const uploadText = model.Busy ? "Please Wait..." : "Import from text";
    return LitHelpers_html(fmt`
        <div>
            ${LitHelpers_html(fmt`
         <form @submit=${(e_5) => {
        const e_4 = e_5;
        startImmediate(singleton.Delay(() => {
            e_4.preventDefault();
            const form = e_4.currentTarget;
            dispatch(new Msg(2, [(form["text"]).value]));
            return singleton.Zero();
        }));
    }}>
                <div>
                    <label class="help" for="text">
- You can delete the text write your query to the below text area.  <br/><br/>
- To use 3DPACK.ING you need to login then subscribe. <br/><br/>
- To switch to manual input, click on "manual entry" above (subscribers only).
                    </label>
            </div>
                    <br/>
                    <br/>
                    <span>
                    <tool-tip inert role="tooltip" tip-position="left">
                    Keywords for orientation:  <br>
                    "keep upright", "keep bottom". Default is none. <br><br>
                    Keywords for stacking: <br>
                    "stackable", "non-stackable". Default is stackable.<br><br>
                    Packing method: "Minimize height", "Minimize length", "use multiple containers" <br>
                    Default is minimize length with single container. <br><br>
                    Keywords for quantity: <br>
                    For only single type of item: <br> you can use "as much" or "unknown quantity" to fill the container with the same item. <br>
                    Won't work if you have multiple types.

</tool-tip> 

                   
                    <textarea rows=15 cols=60 id="text" maxlength="2000" placeholder = "I have a 40 ft HQ container and I would like to fill it with following items:

- 10 boxes of Clothes, 100x140x170 cm, 5kg each, non stackable.

- 8 boxes of Shoes, 80x120x170 cm, 3kg each, keep boxes upright.

- Dimensions are given as L x W x H

- Assume all pallets are untiltable.

- Try to fit everything into a single container.
" name="text">I have a 40 ft HQ container and I would like to fill it with following items:

- 10 boxes of Clothes, 100x140x170 cm, 5kg each, non stackable.

- 8 boxes of Shoes, 80x120x170 cm, 3kg each, keep boxes upright.

- Assume all pallets are untiltable.

- Dimensions are given as L x W x H

- Try to fit everything into a single container.
                    </textarea>
    </span>
                    
                <br/>
                <br/>
                <div>
                    ${LitHelpers_html(fmt`
                <bindrake-calculate-button  isbusy='${model.Busy}' timer=240></bindrake-calculate-button>
               `)}
                </div>
         </form>
        `)}
        </div>
    `);
});

export const FillFromExcel = (new LitElementAttribute("bindrake-fillfromai")).Decorate(function () {
    Hook_useHmr_ZF11B4D8(hmr, this);
    const host = (this).init((arg) => {
        let a;
        a = (arg.useShadowDom = true);
        return Promise.resolve(undefined);
    })[0];
    cleanDSL(host);
    const userAttribute = document.querySelector("bfb-login").getAttribute("user");
    const isLoggedIn = (userAttribute === defaultOf()) ? false : (!(userAttribute === ""));
    const program = Program_mkHiddenProgramWithOrderExecute(() => init(isLoggedIn, undefined), update, (order, dispatch) => {
        execute(host, order, dispatch);
    });
    const patternInput_1 = LitElmishExtensions_useElmish(Hook_getContext_343DAFF1(this), () => program);
    return view(host, patternInput_1[0], patternInput_1[1]);
});

export function register() {
}

