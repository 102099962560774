import { Union, Record } from "../../fable_modules/fable-library-js.4.20.0/Types.js";
import { union_type, record_type, int32_type, bool_type } from "../../fable_modules/fable-library-js.4.20.0/Reflection.js";

export class Model extends Record {
    constructor(IsBusy, Timer, TimerCurrent) {
        super();
        this.IsBusy = IsBusy;
        this.Timer = (Timer | 0);
        this.TimerCurrent = (TimerCurrent | 0);
    }
}

export function Model_$reflection() {
    return record_type("BestFitBox.MVU.Components.CalculateButton.Model", [], Model, () => [["IsBusy", bool_type], ["Timer", int32_type], ["TimerCurrent", int32_type]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["SetBusy", "DecrementTimer"];
    }
}

export function Msg_$reflection() {
    return union_type("BestFitBox.MVU.Components.CalculateButton.Msg", [], Msg, () => [[["Item", bool_type]], []]);
}

export class Order extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["None", "StartTimer", "StopTimer"];
    }
}

export function Order_$reflection() {
    return union_type("BestFitBox.MVU.Components.CalculateButton.Order", [], Order, () => [[], [], []]);
}

export function init(isBusy, timer, unitVar) {
    return [new Model(isBusy, timer, timer), new Order(0, [])];
}

export function update(msg, model) {
    if (msg.tag === 1) {
        return [new Model(model.IsBusy, model.Timer, model.TimerCurrent - 1), new Order(0, [])];
    }
    else {
        const isBusy = msg.fields[0];
        if (isBusy === model.IsBusy) {
            return [model, new Order(0, [])];
        }
        else if (isBusy) {
            return [new Model(isBusy, model.Timer, model.TimerCurrent), new Order(1, [])];
        }
        else {
            return [new Model(isBusy, model.Timer, model.Timer), new Order(2, [])];
        }
    }
}

