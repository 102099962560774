import { Remoting_buildProxy_64DC51C } from "../fable_modules/Fable.Remoting.Client.7.32.0/Remoting.fs.js";
import { RemotingModule_createApi, RemotingModule_withRouteBuilder } from "../fable_modules/Fable.Remoting.Client.7.32.0/Remoting.fs.js";
import { Calculation_$reflection, Route_builder } from "../Domain/API.js";
import { Lit_LitElement__LitElement_dispatchCustomEvent_1EA4E0D4 } from "../fable_modules/Fable.Lit.1.4.2/LitElement.fs.js";
import { FSharpMap__get_Item, FSharpMap__ContainsKey, FSharpMap__Add, ofSeq } from "../fable_modules/fable-library-js.4.20.0/Map.js";
import { map, toList } from "../fable_modules/fable-library-js.4.20.0/Seq.js";
import { toString } from "../fable_modules/fable-library-js.4.20.0/Types.js";
import { defaultOf, int32ToString, comparePrimitives } from "../fable_modules/fable-library-js.4.20.0/Util.js";
import { Auto_generateBoxedEncoder_437914C6, toString as toString_1 } from "../fable_modules/Thoth.Json.10.2.0/Encode.fs.js";
import { class_type, string_type } from "../fable_modules/fable-library-js.4.20.0/Reflection.js";
import { ignore, startImmediate } from "../fable_modules/fable-library-js.4.20.0/Async.js";
import { singleton } from "../fable_modules/fable-library-js.4.20.0/AsyncBuilder.js";
import { Msg } from "../MVU/BinDrakeInputForm.js";
import { printf, toText } from "../fable_modules/fable-library-js.4.20.0/String.js";
import { tail, head, isEmpty } from "../fable_modules/fable-library-js.4.20.0/List.js";
import { ProxyRequestException__get_ResponseText, ProxyRequestException } from "../fable_modules/Fable.Remoting.Client.7.32.0/Types.fs.js";
import { FSharpResult$2 } from "../fable_modules/fable-library-js.4.20.0/Result.js";
import { FSharp_Control_IAsyncObservable$1__IAsyncObservable$1_SubscribeAsync_307B9986, System_IObservable$1__IObservable$1_ToAsyncObservable, AsyncRx_filter } from "../fable_modules/FSharp.Control.AsyncRx.1.6.7/AsyncObservable.fs.js";
import { store } from "../Store.js";

export const Server_api = Remoting_buildProxy_64DC51C(RemotingModule_withRouteBuilder(Route_builder, RemotingModule_createApi()), Calculation_$reflection());

export function execute(host, order, dispatch) {
    switch (order.tag) {
        case 6: {
            Lit_LitElement__LitElement_dispatchCustomEvent_1EA4E0D4(host, "buyCreditsRequested", undefined, true, true);
            break;
        }
        case 1: {
            const nextRowIndex = order.fields[1];
            const form = host.shadowRoot.querySelector("#calc-form");
            const formData_1 = new FormData(form);
            const kvp = ofSeq(toList(map((tupledArg) => [tupledArg[0], toString(tupledArg[1])], formData_1.entries())), {
                Compare: comparePrimitives,
            });
            const kvp_1 = (nextRowIndex != null) ? FSharpMap__Add(kvp, "nextRowIndex", int32ToString(nextRowIndex)) : kvp;
            const str = toString_1(4, Auto_generateBoxedEncoder_437914C6(class_type("Microsoft.FSharp.Collections.FSharpMap`2", [string_type, string_type]), undefined, undefined, undefined)(kvp_1));
            window.localStorage.setItem("bindrake-form-data", str);
            Lit_LitElement__LitElement_dispatchCustomEvent_1EA4E0D4(host, "loginRequested", undefined, true, true);
            break;
        }
        case 2: {
            startImmediate(singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(Server_api.BinDrakeCalculate(order.fields[0]), (_arg_1) => {
                const result = _arg_1;
                dispatch(new Msg(3, [result]));
                if (result.tag === 1) {
                    const e = result.fields[0];
                    let matchResult, s;
                    if (!isEmpty(e)) {
                        if (head(e).tag === 4) {
                            if (isEmpty(tail(e))) {
                                matchResult = 0;
                                s = head(e).fields[0];
                            }
                            else {
                                matchResult = 1;
                            }
                        }
                        else {
                            matchResult = 1;
                        }
                    }
                    else {
                        matchResult = 1;
                    }
                    switch (matchResult) {
                        case 0: {
                            dispatch(new Msg(4, [s]));
                            return singleton.Zero();
                        }
                        default: {
                            dispatch(new Msg(4, [toText(printf("%A"))(e)]));
                            return singleton.Zero();
                        }
                    }
                }
                else {
                    Lit_LitElement__LitElement_dispatchCustomEvent_1EA4E0D4(host, "calculationCompleted", result.fields[0].CreditVersion, true, true);
                    return singleton.Zero();
                }
            })), (_arg_2) => {
                if (_arg_2 instanceof ProxyRequestException) {
                    const ex = _arg_2;
                    dispatch(new Msg(4, [ProxyRequestException__get_ResponseText(ex)]));
                    return singleton.Zero();
                }
                else {
                    throw _arg_2;
                    return defaultOf();
                }
            })));
            break;
        }
        case 3: {
            const ex_1 = order.fields[0];
            if (ex_1.indexOf("subscribe!") >= 0) {
                Lit_LitElement__LitElement_dispatchCustomEvent_1EA4E0D4(host, "buyCreditsRequested", ex_1, true, true);
            }
            else {
                navigator.sendBeacon("/log-error", ex_1);
                window.showError(ex_1);
            }
            break;
        }
        case 4: {
            const guid = order.fields[0];
            startImmediate(singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(singleton.Delay(() => {
                if (guid === "default-data") {
                    const matchValue = localStorage.getItem("last-calc");
                    return (matchValue === defaultOf()) ? singleton.ReturnFrom(Server_api.BinDrakeLoadDefaultData(guid, "bindrake")) : singleton.ReturnFrom(Server_api.BinDrakeLoadData(matchValue, "bindrake"));
                }
                else {
                    return singleton.ReturnFrom(Server_api.BinDrakeLoadData(guid, "bindrake"));
                }
            }), (_arg_3) => {
                const result_2 = _arg_3;
                const formData_2 = result_2.BinDrakeInput.FormData;
                return singleton.Combine(!(FSharpMap__ContainsKey(formData_2, "aiText") && (FSharpMap__get_Item(formData_2, "aiText").length > 0)) ? singleton.TryWith(singleton.Delay(() => {
                    document.querySelector("bindrake-manual-entry").shadowRoot.querySelector("button").click();
                    return singleton.Zero();
                }), (_arg_4) => {
                    return singleton.Zero();
                }) : singleton.Zero(), singleton.Delay(() => {
                    dispatch(new Msg(3, [new FSharpResult$2(0, [result_2])]));
                    return singleton.Zero();
                }));
            })), (_arg_5) => {
                navigator.sendBeacon("/log-error", _arg_5.message);
                dispatch(new Msg(4, [""]));
                return singleton.Zero();
            })));
            break;
        }
        case 7: {
            break;
        }
        case 8: {
            const color = host.shadowRoot.querySelector("[name=item-0-color]");
            const name = host.shadowRoot.querySelector("[name=item-0-name]");
            const weight = host.shadowRoot.querySelector("[name=item-0-weight]");
            const priority = host.shadowRoot.querySelector("[name=item-0-priority]");
            const quantity = host.shadowRoot.querySelector("[name=item-0-quantity]");
            const cMaxWeight = host.shadowRoot.querySelector("[name=container-maxWeight]");
            const frontSpace = host.shadowRoot.querySelector("[name=front-space]");
            const backSpace = host.shadowRoot.querySelector("[name=back-space]");
            const leftSpace = host.shadowRoot.querySelector("[name=left-space]");
            const rightSpace = host.shadowRoot.querySelector("[name=right-space]");
            const topSpace = host.shadowRoot.querySelector("[name=top-space]");
            window.setTimeout((_arg_6) => {
                color.value = "#42d4f4";
                name.value = "Item 1";
                weight.value = "0";
                priority.value = "1";
                quantity.value = "1";
                cMaxWeight.value = "0";
                topSpace.value = "0";
                backSpace.value = "0";
                leftSpace.value = "0";
                rightSpace.value = "0";
                frontSpace.value = "0";
            }, 100);
            break;
        }
        case 0: {
            break;
        }
        default:
            startImmediate(singleton.Delay(() => {
                const asyncStore = AsyncRx_filter((x) => (x.Credit != null), System_IObservable$1__IObservable$1_ToAsyncObservable(store));
                return singleton.Bind(ignore(FSharp_Control_IAsyncObservable$1__IAsyncObservable$1_SubscribeAsync_307B9986(asyncStore, (model) => {
                    let matchResult_1, credit;
                    if (model.tag === 0) {
                        if (model.fields[0].Credit != null) {
                            matchResult_1 = 0;
                            credit = model.fields[0].Credit;
                        }
                        else {
                            matchResult_1 = 1;
                        }
                    }
                    else {
                        matchResult_1 = 1;
                    }
                    switch (matchResult_1) {
                        case 0: {
                            dispatch(new Msg(11, [credit.Credit]));
                            break;
                        }
                    }
                    return singleton.Delay(() => singleton.Return(undefined));
                })), () => singleton.Return(undefined));
            }));
    }
}

