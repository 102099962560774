import { Prop_Of_Z4A92C983, LitElementAttribute, Lit_LitElement__LitElement_dispatchCustomEvent_1EA4E0D4 } from "./fable_modules/Fable.Lit.1.4.2/LitElement.fs.js";
import { startImmediate } from "./fable_modules/fable-library-js.4.20.0/Async.js";
import { singleton } from "./fable_modules/fable-library-js.4.20.0/AsyncBuilder.js";
import { cleanDSL, retry } from "./ViewFunctions.js";
import { Server_getSubscription } from "./Store.js";
import { Version } from "./Domain/Model.js";
import { update, init, Msg } from "./MVU/BuyCredits.js";
import { Hook_createDisposable_3A5B6456, HookComponentAttribute } from "./fable_modules/Fable.Lit.1.4.2/Hook.fs.js";
import { Lit_HookContext__HookContext_useEffectOnChange_31A5188A, Hook_getContext_343DAFF1, Hook_useHmr_ZF11B4D8 } from "./fable_modules/Fable.Lit.1.4.2/Hook.fs.js";
import { defaultArg, value as value_4 } from "./fable_modules/fable-library-js.4.20.0/Option.js";
import { Lit_refValue_Z4DF72B44, LitHelpers_html } from "./fable_modules/Fable.Lit.1.4.2/Lit.fs.js";
import { fmt } from "./fable_modules/fable-library-js.4.20.0/String.js";
import { parse } from "./fable_modules/fable-library-js.4.20.0/Boolean.js";
import { Program_mkHiddenProgramWithOrderExecute } from "./ElmishOrder.js";
import { LitElmishExtensions_useElmish } from "./fable_modules/Fable.Lit.Elmish.1.4.0/Lit.Elmish.fs.js";

const hmr = undefined;

export function execute(host, order, dispatch) {
    switch (order.tag) {
        case 2: {
            Lit_LitElement__LitElement_dispatchCustomEvent_1EA4E0D4(host, "loginRequested", undefined, true, true);
            break;
        }
        case 1: {
            startImmediate(singleton.Delay(() => singleton.Bind(retry(() => Server_getSubscription(order.fields[0], new Version(0n))), (_arg) => {
                dispatch(new Msg(5, [_arg]));
                return singleton.Zero();
            })));
            break;
        }
        default:
            undefined;
    }
}

export const view = (new HookComponentAttribute()).Decorate(function (host, model, dispatch) {
    Hook_useHmr_ZF11B4D8(hmr, this);
    const dialogRef = Hook_getContext_343DAFF1(this).useRef(() => undefined);
    Lit_HookContext__HookContext_useEffectOnChange_31A5188A(Hook_getContext_343DAFF1(this), model.BuyCreditDetails, (_arg) => {
        if (model.BuyCreditDetails) {
            value_4(dialogRef.contents).showModal();
        }
        else {
            value_4(dialogRef.contents).close();
        }
    });
    Hook_getContext_343DAFF1(this).useEffectOnce(() => {
        const dialogCloseHandler = (e) => {
            dispatch(new Msg(4, []));
        };
        value_4(dialogRef.contents).addEventListener("close", dialogCloseHandler);
        const buyCredits = (e_1) => {
            const customEvent = e_1;
            dispatch(new Msg(0, [customEvent.detail]));
        };
        document.addEventListener("buyCreditsRequested", buyCredits);
        const loginHandler = (e_2) => {
            const customEvent_1 = e_2;
            dispatch(new Msg(3, [customEvent_1.detail]));
        };
        document.addEventListener("loggedIn", loginHandler);
        const logoutHandler = (e_3) => {
            dispatch(new Msg(2, []));
        };
        document.addEventListener("loggedOut", logoutHandler);
        return Hook_createDisposable_3A5B6456(() => {
            document.removeEventListener("loggedIn", loginHandler);
            document.removeEventListener("loggedOut", logoutHandler);
            document.removeEventListener("buyCreditsRequested", buyCredits);
            value_4(dialogRef.contents).removeEventListener("close", dialogCloseHandler);
        });
    });
    const helpIcon = LitHelpers_html(fmt`
            <svg class="help" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                width="100" height="100"
                viewBox="0 0 24 24">
                <path d="M 12 2 C 6.4889971 2 2 6.4889971 2 12 C 2 17.511003 6.4889971 22 12 22 C 17.511003 22 22 17.511003 22 12 C 22 6.4889971 17.511003 2 12 2 z M 12 4 C 16.430123 4 20 7.5698774 20 12 C 20 16.430123 16.430123 20 12 20 C 7.5698774 20 4 16.430123 4 12 C 4 7.5698774 7.5698774 4 12 4 z M 12 6 C 9.79 6 8 7.79 8 10 L 10 10 C 10 8.9 10.9 8 12 8 C 13.1 8 14 8.9 14 10 C 14 12 11 12.367 11 15 L 13 15 C 13 13.349 16 12.5 16 10 C 16 7.79 14.21 6 12 6 z M 11 16 L 11 18 L 13 18 L 13 16 L 11 16 z"></path>
            </svg>
        `);
    const cheapSubs = LitHelpers_html(fmt`
              <div>
                                <input type="radio" id="control_05" name="select" value="5" checked>
                                <label for="control_05">
                                <h3>$2</h3>
                                    <p class=price>Monthly subscription<br>
                                    <small> <br> You get a receipt every month</small></p>
                                    </label>
                             </div>
            `);
    const formContent = !model.Subscriber ? LitHelpers_html(fmt`
            <h3 class=error> ${defaultArg(model.Msg, "")} </h3>
            <form action="/create-checkout-session" method="POST">
                    <section>
                        
                             <div>
                                <input type="radio" id="control_05" name="select" value="5" checked>
                                <label for="control_05">
                                <h3>$12 / month</h3>
                                    <p class=price> Unlimited, monthly subscription. <br>
                                    <small> <br> Renews automatically. You can cancel any time and you get a receipt every month when you make payment.</small></p>
                                    <small> <br></small></p>
                                    </label>
                            </div>
                            <div>
                                <input type="radio" id="control_07" name="select" value="7">
                                <label for="control_07">
                                <h3>$99</h3>
                                    <p class=price>Unlimited 1 Year usage.  <br>
                                    <small> 
                                     One time payment. Does not renew. You get a receipt </small></p>
                                    </label>
                            </div> 
                        </section>
                        <div class=share-calcs>
                          <br/>
            
                            <label for=share-credits>Share Calculations/Subscription within my organization domain:
                              
                            </label>
                            <input type=checkbox id=share-credits name=share-credits>
                            <span> ${helpIcon}  
                                    <tool-tip inert role="tooltip" tip-position="left">
                                    If you check this box, all users with an email address from your organization domain will also be able to use BinDrake free of charge.
                                    </tool-tip> 
                             </span>
                        </div>
                        <button onclick="gtag_report_conversion()" type="submit" id="checkout-button">💳 Pay securely with Credit Card or PayPal 🔒</button>

                    </form>`) : LitHelpers_html(fmt`
            <form action="/show-portal" method="POST">
            <section>
                <div>
                <input type="radio" id="control_01" name="select" value="1" checked>
                <label for="control_01">
                    <h3>Your subscription currently is active!</h3>
                </label>
                </div>
        </section>
            <section>
                <button type="submit" id="subs">Manage your subscription</button>
            </form>
            `);
    return LitHelpers_html(fmt`
        <div ?hidden=${!model.Show}>
            <span>
                <button type="button" class=good @click=${(_arg_1) => {
        dispatch(new Msg(0, [undefined]));
    }}>${model.Subscriber ? "Your subscription" : "Subscribe!"}</button>
                <tool-tip inert role="tooltip" tip-position="bottom">
                You can buy or manage your 'subscriptions' here.
                   Payments are handled by Stripe and we do not have access to your credit card details.
                </tool-tip> 
            </span>
            <dialog ${Lit_refValue_Z4DF72B44(dialogRef)}>
                <button type=button class="close deny" @click=${(_arg_2) => {
        dispatch(new Msg(1, []));
    }}>
                    <img src="/assets/icons/icons8-close.svg" alt='close button'>
                </button>
                ${formContent}
            </dialog>
        </div>
    `);
});

export const BuyCredits = (new LitElementAttribute("bfb-buycredits")).Decorate(function () {
    Hook_useHmr_ZF11B4D8(hmr, this);
    const patternInput = (this).init((arg) => {
        let userLoggedIn;
        let a;
        const config = arg;
        config.useShadowDom = true;
        a = (config.props = ((userLoggedIn = Prop_Of_Z4A92C983("false"), {
            subscriber: Prop_Of_Z4A92C983("false"),
            userLoggedIn: userLoggedIn,
        })));
        return Promise.resolve(undefined);
    });
    const props = patternInput[1];
    const host = patternInput[0];
    cleanDSL(host);
    const loggedIn = parse(props.userLoggedIn);
    const subscriber = parse(props.subscriber);
    const program = Program_mkHiddenProgramWithOrderExecute(() => init(loggedIn, subscriber, undefined), update, (order, dispatch) => {
        execute(host, order, dispatch);
    });
    const patternInput_1 = LitElmishExtensions_useElmish(Hook_getContext_343DAFF1(this), () => program);
    return view(host, patternInput_1[0], patternInput_1[1]);
});

export function register() {
}

