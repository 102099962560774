import { Record, toString, Union } from "../fable_modules/fable-library-js.4.20.0/Types.js";
import { decimal_type, list_type, bool_type, option_type, int32_type, class_type, record_type, union_type, obj_type, int64_type, string_type } from "../fable_modules/fable-library-js.4.20.0/Reflection.js";
import { Validator$1__To_7C4B0DD6, Validator$1__Gte, Validator$1__Lte, Validator$1__Test, Validator$1__MinLen, Validator$1__MaxLen, Validator$1__Map_7C4B0DD6, Validator$1__End_Z2CAB7B75, single } from "./Fable.Validation.js";
import { toConsole, printf, toText, split, substring, replace, join } from "../fable_modules/fable-library-js.4.20.0/String.js";
import { head, tryFind, item as item_5, mapIndexed, ofArray, sortByDescending, singleton, map } from "../fable_modules/fable-library-js.4.20.0/List.js";
import { map as map_1, item as item_4 } from "../fable_modules/fable-library-js.4.20.0/Array.js";
import { max as max_1, op_Division as op_Division_1, compare, fromDecimal, fromInt32, toDecimal, op_Multiply, toInt64 } from "../fable_modules/fable-library-js.4.20.0/BigInt.js";
import { equals, defaultOf } from "../fable_modules/fable-library-js.4.20.0/Util.js";
import { op_Division, fromParts, toString as toString_1, parse, round, op_Multiply as op_Multiply_1 } from "../fable_modules/fable-library-js.4.20.0/Decimal.js";
import Decimal from "../fable_modules/fable-library-js.4.20.0/Decimal.js";
import { FSharpResult$2 } from "../fable_modules/fable-library-js.4.20.0/Result.js";
import { defaultArg } from "../fable_modules/fable-library-js.4.20.0/Option.js";
import { parse as parse_1 } from "../fable_modules/fable-library-js.4.20.0/Long.js";
import { parse as parse_2 } from "../fable_modules/fable-library-js.4.20.0/Int32.js";

export class Predicate extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Greater", "GreaterOrEqual", "Smaller", "SmallerOrEqual", "Equal", "NotEqual", "And", "Or", "Not"];
    }
}

export function Predicate_$reflection() {
    return union_type("BestFitBox.Domain.Model.Predicate", [], Predicate, () => [[["Item1", string_type], ["Item2", int64_type]], [["Item1", string_type], ["Item2", int64_type]], [["Item1", string_type], ["Item2", int64_type]], [["Item1", string_type], ["Item2", int64_type]], [["Item1", string_type], ["Item2", obj_type]], [["Item1", string_type], ["Item2", obj_type]], [["Item1", Predicate_$reflection()], ["Item2", Predicate_$reflection()]], [["Item1", Predicate_$reflection()], ["Item2", Predicate_$reflection()]], [["Item", Predicate_$reflection()]]]);
}

export class Version extends Union {
    constructor(Item) {
        super();
        this.tag = 0;
        this.fields = [Item];
    }
    cases() {
        return ["Version"];
    }
}

export function Version_$reflection() {
    return union_type("BestFitBox.Domain.Model.Version", [], Version, () => [[["Item", int64_type]]]);
}

export function Version__get_Value(this$) {
    return this$.fields[0];
}

export class ShortStringError extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["EmptyString", "TooLongString"];
    }
}

export function ShortStringError_$reflection() {
    return union_type("BestFitBox.Domain.Model.ShortStringError", [], ShortStringError, () => [[], []]);
}

export class ShortString extends Union {
    constructor(Item) {
        super();
        this.tag = 0;
        this.fields = [Item];
    }
    cases() {
        return ["ShortString"];
    }
}

export function ShortString_$reflection() {
    return union_type("BestFitBox.Domain.Model.ShortString", [], ShortString, () => [[["Item", string_type]]]);
}

export function ShortString__get_Value(this$) {
    return this$.fields[0];
}

export function ShortString_TryCreate_Z721C83C5(s) {
    return single((t) => Validator$1__End_Z2CAB7B75(t, Validator$1__Map_7C4B0DD6(t, (Item) => (new ShortString(Item)))(Validator$1__MaxLen(t, 255, new ShortStringError(1, []), Validator$1__MinLen(t, 1, new ShortStringError(0, []), Validator$1__Test(t, "s", s))))));
}

export function ShortString_Validate_2D476C0D(s) {
    const e_1 = ShortString_TryCreate_Z721C83C5(ShortString__get_Value(s));
    if (e_1.tag === 1) {
        throw new Error(join(", ", map((x_2) => {
            let copyOfStruct = x_2;
            return toString(copyOfStruct);
        }, e_1.fields[0])));
    }
    else {
        return e_1.fields[0];
    }
}

export class LongStringError extends Union {
    constructor() {
        super();
        this.tag = 0;
        this.fields = [];
    }
    cases() {
        return ["EmptyString"];
    }
}

export function LongStringError_$reflection() {
    return union_type("BestFitBox.Domain.Model.LongStringError", [], LongStringError, () => [[]]);
}

export class LongString extends Union {
    constructor(Item) {
        super();
        this.tag = 0;
        this.fields = [Item];
    }
    cases() {
        return ["LongString"];
    }
}

export function LongString_$reflection() {
    return union_type("BestFitBox.Domain.Model.LongString", [], LongString, () => [[["Item", string_type]]]);
}

export function LongString__get_Value(this$) {
    return this$.fields[0];
}

export function LongString_TryCreate_Z721C83C5(s) {
    return single((t) => Validator$1__End_Z2CAB7B75(t, Validator$1__Map_7C4B0DD6(t, (Item) => (new LongString(Item)))(Validator$1__MinLen(t, 1, new LongStringError(), Validator$1__Test(t, "s", s)))));
}

export function LongString_Validate_344AAA75(s) {
    const e_1 = LongString_TryCreate_Z721C83C5(LongString__get_Value(s));
    if (e_1.tag === 1) {
        throw new Error(join(", ", map((x_2) => {
            let copyOfStruct = x_2;
            return toString(copyOfStruct);
        }, e_1.fields[0])));
    }
    else {
        return e_1.fields[0];
    }
}

export class Authentication_EmailError extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["EmptyEmail", "InvalidEmailAddress"];
    }
}

export function Authentication_EmailError_$reflection() {
    return union_type("BestFitBox.Domain.Model.Authentication.EmailError", [], Authentication_EmailError, () => [[], []]);
}

export class Authentication_Email extends Union {
    constructor(Item) {
        super();
        this.tag = 0;
        this.fields = [Item];
    }
    cases() {
        return ["Email"];
    }
}

export function Authentication_Email_$reflection() {
    return union_type("BestFitBox.Domain.Model.Authentication.Email", [], Authentication_Email, () => [[["Item", string_type]]]);
}

export function Authentication_Email__get_Value(this$) {
    return this$.fields[0];
}

export function Authentication_Email_TryCreate_Z721C83C5(email) {
    const email_1 = replace(email.trim(), " ", "");
    let email_3;
    if (email_1.indexOf("@") >= 0) {
        email_3 = email_1;
    }
    else {
        const email_2 = replace(replace(replace(email_1, "(", ""), ")", ""), "-", "");
        email_3 = ((email_2.indexOf("00") === 0) ? ("+" + substring(email_2, 2)) : (!(email_2.indexOf("+") === 0) ? ("+" + email_2) : email_2));
    }
    return single((t) => Validator$1__End_Z2CAB7B75(t, Validator$1__Map_7C4B0DD6(t, (x_1) => {
        let left, right;
        const lowerCase = x_1.toLowerCase();
        return new Authentication_Email(((lowerCase.indexOf("@gmail") >= 0) && (lowerCase.indexOf(".") >= 0)) ? ((left = item_4(0, split(lowerCase, ["@"], undefined, 0)), (right = item_4(1, split(lowerCase, ["@"], undefined, 0)), (replace(left, ".", "") + "@") + right))) : lowerCase);
    })(Validator$1__MaxLen(t, 50, new Authentication_EmailError(1, []), Validator$1__MinLen(t, 1, new Authentication_EmailError(0, []), Validator$1__Test(t, "s", email_3))))));
}

export function Authentication_Email_Validate_Z34AB4178(s) {
    const e_1 = Authentication_Email_TryCreate_Z721C83C5(Authentication_Email__get_Value(s));
    if (e_1.tag === 1) {
        throw new Error(join(", ", map((x_2) => {
            let copyOfStruct = x_2;
            return toString(copyOfStruct);
        }, e_1.fields[0])));
    }
    else {
        return e_1.fields[0];
    }
}

export class Authentication_User extends Record {
    constructor(Id) {
        super();
        this.Id = Id;
    }
}

export function Authentication_User_$reflection() {
    return record_type("BestFitBox.Domain.Model.Authentication.User", [], Authentication_User, () => [["Id", Authentication_Email_$reflection()]]);
}

export class Authentication_VerificationError extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["EmptyVerificationCode", "InvalidVerificationCode"];
    }
}

export function Authentication_VerificationError_$reflection() {
    return union_type("BestFitBox.Domain.Model.Authentication.VerificationError", [], Authentication_VerificationError, () => [[], []]);
}

export class Authentication_VerificationCode extends Union {
    constructor(Item) {
        super();
        this.tag = 0;
        this.fields = [Item];
    }
    cases() {
        return ["VerificationCode"];
    }
}

export function Authentication_VerificationCode_$reflection() {
    return union_type("BestFitBox.Domain.Model.Authentication.VerificationCode", [], Authentication_VerificationCode, () => [[["Item", string_type]]]);
}

export function Authentication_VerificationCode__get_Value(this$) {
    return this$.fields[0];
}

export function Authentication_VerificationCode_TryCreate_Z721C83C5(s) {
    return single((t) => Validator$1__End_Z2CAB7B75(t, Validator$1__Map_7C4B0DD6(t, (Item) => (new Authentication_VerificationCode(Item)))(Validator$1__MaxLen(t, 6, new Authentication_VerificationError(1, []), Validator$1__MinLen(t, 1, new Authentication_VerificationError(0, []), Validator$1__Test(t, "s", s))))));
}

export class Authentication_VisitorId extends Record {
    constructor(Id, UserId, CreateDate) {
        super();
        this.Id = Id;
        this.UserId = UserId;
        this.CreateDate = CreateDate;
    }
}

export function Authentication_VisitorId_$reflection() {
    return record_type("BestFitBox.Domain.Model.Authentication.VisitorId", [], Authentication_VisitorId, () => [["Id", ShortString_$reflection()], ["UserId", Authentication_Email_$reflection()], ["CreateDate", class_type("System.DateTime")]]);
}

export class Calculation_Multiplier extends Union {
    constructor(Item) {
        super();
        this.tag = 0;
        this.fields = [Item];
    }
    cases() {
        return ["Multiplier"];
    }
}

export function Calculation_Multiplier_$reflection() {
    return union_type("BestFitBox.Domain.Model.Calculation.Multiplier", [], Calculation_Multiplier, () => [[["Item", int64_type]]]);
}

export function Calculation_Multiplier__get_Value(this$) {
    return this$.fields[0];
}

export class Calculation_DimError extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["EmptyDim", "Graceful", "InvalidDim"];
    }
}

export function Calculation_DimError_$reflection() {
    return union_type("BestFitBox.Domain.Model.Calculation.DimError", [], Calculation_DimError, () => [[], [["Item", string_type]], [["Item", string_type]]]);
}

export class Calculation_Dim extends Union {
    constructor(Item) {
        super();
        this.tag = 0;
        this.fields = [Item];
    }
    cases() {
        return ["Dim"];
    }
}

export function Calculation_Dim_$reflection() {
    return union_type("BestFitBox.Domain.Model.Calculation.Dim", [], Calculation_Dim, () => [[["Item", int64_type]]]);
}

export function Calculation_Dim__get_Value(this$) {
    return this$.fields[0];
}

function Calculation_Dim_CheckRange(t, multiplier, fieldInfo) {
    return Validator$1__End_Z2CAB7B75(t, Validator$1__Map_7C4B0DD6(t, (Item) => (new Calculation_Dim(Item)))(Validator$1__Lte(t, toInt64(op_Multiply(20000n, 100n)), new Calculation_DimError(2, ["Can\'t be greater than 20000"]))(Validator$1__Gte(t, 0n, new Calculation_DimError(2, ["Can\'t be lower than 0"]))(fieldInfo))));
}

export function Calculation_Dim_TryCreate_Z5095E5A6(s, multiplier, error, unitMultiplier) {
    let s_3;
    if (s !== defaultOf()) {
        let v;
        let copyOfStruct = op_Multiply_1(round(parse(s), 2), new Decimal(multiplier));
        v = toString_1(copyOfStruct);
        if (unitMultiplier == null) {
            s_3 = s;
        }
        else {
            const x = unitMultiplier;
            let copyOfStruct_1 = op_Multiply_1(round(parse(v), 2), x);
            s_3 = toString_1(copyOfStruct_1);
        }
    }
    else {
        s_3 = s;
    }
    if (equals(unitMultiplier, fromParts(1, 0, 0, true, 0))) {
        return new FSharpResult$2(1, [singleton(new Calculation_DimError(1, ["You cannot mix metric and Imperial units"]))]);
    }
    else {
        const error_1 = defaultArg(error, "Not convertable to int 64");
        return single((t) => Calculation_Dim_CheckRange(t, multiplier, Validator$1__To_7C4B0DD6(t, (x_2) => toInt64(parse_1(x_2, 511, false, 64)))(new Calculation_DimError(2, [error_1]))(Validator$1__Test(t, "s", s_3))));
    }
}

export function Calculation_Dim_TryCreate_Z524259C1(i) {
    return single((t) => Calculation_Dim_CheckRange(t, 1n, Validator$1__Test(t, "s", i)));
}

export function Calculation_Dim_Validate_Z1BC59693(dim) {
    const e_1 = Calculation_Dim_TryCreate_Z524259C1(Calculation_Dim__get_Value(dim));
    if (e_1.tag === 1) {
        throw new Error(join(", ", map((x_2) => {
            let copyOfStruct = x_2;
            return toString(copyOfStruct);
        }, e_1.fields[0])));
    }
    else {
        return e_1.fields[0];
    }
}

export class Calculation_Weight extends Union {
    constructor(Item) {
        super();
        this.tag = 0;
        this.fields = [Item];
    }
    cases() {
        return ["Weight"];
    }
}

export function Calculation_Weight_$reflection() {
    return union_type("BestFitBox.Domain.Model.Calculation.Weight", [], Calculation_Weight, () => [[["Item", int32_type]]]);
}

export function Calculation_Weight__get_Value(this$) {
    return this$.fields[0];
}

function Calculation_Weight_CheckRange(t, fieldInfo) {
    return Validator$1__End_Z2CAB7B75(t, Validator$1__Map_7C4B0DD6(t, (Item) => (new Calculation_Weight(Item)))(Validator$1__Lte(t, 200000 * 100, new Calculation_DimError(2, ["Weight can\'t be greater than 200000"]))(Validator$1__Gte(t, 0, new Calculation_DimError(2, ["Weight can\'t be lower than zero"]))(fieldInfo))));
}

export function Calculation_Weight_TryCreate_Z5095E5A6(s, multiplier, error, unitMultiplier) {
    let s_3;
    if (s !== defaultOf()) {
        let v;
        let copyOfStruct = op_Multiply_1(round(parse(s), 2), new Decimal(multiplier));
        v = toString_1(copyOfStruct);
        if (unitMultiplier == null) {
            s_3 = s;
        }
        else {
            const x = unitMultiplier;
            let copyOfStruct_1 = op_Multiply_1(round(parse(v), 2), x);
            s_3 = toString_1(copyOfStruct_1);
        }
    }
    else {
        s_3 = s;
    }
    if (equals(unitMultiplier, fromParts(1, 0, 0, true, 0))) {
        return new FSharpResult$2(1, [singleton(new Calculation_DimError(1, ["You cannot mix metric and Imperial units"]))]);
    }
    else {
        const error_1 = defaultArg(error, "Weight: Not convertable to int 64");
        return single((t) => Calculation_Weight_CheckRange(t, Validator$1__To_7C4B0DD6(t, (value_3) => parse_2(value_3, 511, false, 32))(new Calculation_DimError(2, [error_1]))(Validator$1__Test(t, "s", s_3))));
    }
}

export function Calculation_Weight_TryCreate_Z524259A4(i) {
    return single((t) => Calculation_Weight_CheckRange(t, Validator$1__Test(t, "s", i)));
}

export function Calculation_Weight_Validate_Z5E52F0F3(weight) {
    const e_1 = Calculation_Weight_TryCreate_Z524259A4(Calculation_Weight__get_Value(weight));
    if (e_1.tag === 1) {
        throw new Error(join(", ", map((x_2) => {
            let copyOfStruct = x_2;
            return toString(copyOfStruct);
        }, e_1.fields[0])));
    }
    else {
        return e_1.fields[0];
    }
}

export class Calculation_Quantity extends Union {
    constructor(Item) {
        super();
        this.tag = 0;
        this.fields = [Item];
    }
    cases() {
        return ["Quantity"];
    }
}

export function Calculation_Quantity_$reflection() {
    return union_type("BestFitBox.Domain.Model.Calculation.Quantity", [], Calculation_Quantity, () => [[["Item", int32_type]]]);
}

export function Calculation_Quantity__get_Value(this$) {
    return this$.fields[0];
}

function Calculation_Quantity_CheckRange(t, fieldInfo) {
    return Validator$1__End_Z2CAB7B75(t, Validator$1__Map_7C4B0DD6(t, (Item) => (new Calculation_Quantity(Item)))(Validator$1__Lte(t, 2000, new Calculation_DimError(2, ["Quantity be greater than 2000"]))(Validator$1__Gte(t, 1, new Calculation_DimError(2, ["Quantity can\'t be lower than 1"]))(fieldInfo))));
}

export function Calculation_Quantity_TryCreate_27AED5E3(s, error) {
    const error_1 = defaultArg(error, "Quantity: Not convertable to int 64");
    return single((t) => Calculation_Quantity_CheckRange(t, Validator$1__To_7C4B0DD6(t, (value_3) => parse_2(value_3, 511, false, 32))(new Calculation_DimError(2, [error_1]))(Validator$1__Test(t, "s", s))));
}

export function Calculation_Quantity_TryCreate_Z524259A4(i) {
    return single((t) => Calculation_Quantity_CheckRange(t, Validator$1__Test(t, "s", i)));
}

export function Calculation_Quantity_Validate_Z5E52F0F3(weight) {
    const e_1 = Calculation_Quantity_TryCreate_Z524259A4(Calculation_Weight__get_Value(weight));
    if (e_1.tag === 1) {
        throw new Error(join(", ", map((x_2) => {
            let copyOfStruct = x_2;
            return toString(copyOfStruct);
        }, e_1.fields[0])));
    }
    else {
        return e_1.fields[0];
    }
}

export class Calculation_LWH extends Record {
    constructor(Length, Width, Height) {
        super();
        this.Length = Length;
        this.Width = Width;
        this.Height = Height;
    }
    toString() {
        const this$ = this;
        const l = Calculation_Dim__get_Value(this$.Length);
        const w = Calculation_Dim__get_Value(this$.Width);
        const h = Calculation_Dim__get_Value(this$.Height);
        return toText(printf("%d x %d x %d"))(l)(w)(h);
    }
}

export function Calculation_LWH_$reflection() {
    return record_type("BestFitBox.Domain.Model.Calculation.LWH", [], Calculation_LWH, () => [["Length", Calculation_Dim_$reflection()], ["Width", Calculation_Dim_$reflection()], ["Height", Calculation_Dim_$reflection()]]);
}

export function Calculation_LWH__ToString_Z524259C1(this$, multiplier) {
    const mdecimal = toDecimal(multiplier);
    const l = op_Division(toDecimal(Calculation_Dim__get_Value(this$.Length)), mdecimal);
    const w = op_Division(toDecimal(Calculation_Dim__get_Value(this$.Width)), mdecimal);
    const h = op_Division(toDecimal(Calculation_Dim__get_Value(this$.Height)), mdecimal);
    return toText(printf("%M x %M x %M"))(l)(w)(h);
}

export function Calculation_LWH_Create_Z721C83C5(lwh) {
    let e_1, e_3, e_5;
    const lwhDims = map_1((s) => parse_2(s.trim(), 511, false, 32), split(lwh, ["x"], undefined, 0), Int32Array);
    return new Calculation_LWH((e_1 = Calculation_Dim_TryCreate_Z524259C1(toInt64(fromInt32(item_4(0, lwhDims)))), (e_1.tag === 1) ? (() => {
        throw new Error(join(", ", map((x_2) => {
            let copyOfStruct = x_2;
            return toString(copyOfStruct);
        }, e_1.fields[0])));
    })() : e_1.fields[0]), (e_3 = Calculation_Dim_TryCreate_Z524259C1(toInt64(fromInt32(item_4(1, lwhDims)))), (e_3.tag === 1) ? (() => {
        throw new Error(join(", ", map((x_2_1) => {
            let copyOfStruct_1 = x_2_1;
            return toString(copyOfStruct_1);
        }, e_3.fields[0])));
    })() : e_3.fields[0]), (e_5 = Calculation_Dim_TryCreate_Z524259C1(toInt64(fromInt32(item_4(2, lwhDims)))), (e_5.tag === 1) ? (() => {
        throw new Error(join(", ", map((x_2_2) => {
            let copyOfStruct_2 = x_2_2;
            return toString(copyOfStruct_2);
        }, e_5.fields[0])));
    })() : e_5.fields[0]));
}

export class Calculation_Container extends Record {
    constructor(LWH, MaxWeight) {
        super();
        this.LWH = LWH;
        this.MaxWeight = MaxWeight;
    }
}

export function Calculation_Container_$reflection() {
    return record_type("BestFitBox.Domain.Model.Calculation.Container", [], Calculation_Container, () => [["LWH", Calculation_LWH_$reflection()], ["MaxWeight", Calculation_Weight_$reflection()]]);
}

export class Calculation_Item extends Record {
    constructor(LWH, Weight, Tag, Quantity, Priority, KeepTop, Stack, KeepBottom) {
        super();
        this.LWH = LWH;
        this.Weight = Weight;
        this.Tag = Tag;
        this.Quantity = Quantity;
        this.Priority = Priority;
        this.KeepTop = KeepTop;
        this.Stack = Stack;
        this.KeepBottom = KeepBottom;
    }
}

export function Calculation_Item_$reflection() {
    return record_type("BestFitBox.Domain.Model.Calculation.Item", [], Calculation_Item, () => [["LWH", Calculation_LWH_$reflection()], ["Weight", Calculation_Weight_$reflection()], ["Tag", string_type], ["Quantity", Calculation_Quantity_$reflection()], ["Priority", option_type(int32_type)], ["KeepTop", bool_type], ["Stack", bool_type], ["KeepBottom", bool_type]]);
}

export class Calculation_Row extends Record {
    constructor(Index, ShowAdd, ShowRemove) {
        super();
        this.Index = (Index | 0);
        this.ShowAdd = ShowAdd;
        this.ShowRemove = ShowRemove;
    }
}

export function Calculation_Row_$reflection() {
    return record_type("BestFitBox.Domain.Model.Calculation.Row", [], Calculation_Row, () => [["Index", int32_type], ["ShowAdd", bool_type], ["ShowRemove", bool_type]]);
}

export class Calculation_Speed extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Slow", "Normal", "Fast"];
    }
}

export function Calculation_Speed_$reflection() {
    return union_type("BestFitBox.Domain.Model.Calculation.Speed", [], Calculation_Speed, () => [[], [], []]);
}

export class Calculation_BFBInput extends Record {
    constructor(FormData, Rows, NextRowIndex, Containers, Item, Multiplier, ExcelString, WeightMultiplier, Speed) {
        super();
        this.FormData = FormData;
        this.Rows = Rows;
        this.NextRowIndex = (NextRowIndex | 0);
        this.Containers = Containers;
        this.Item = Item;
        this.Multiplier = Multiplier;
        this.ExcelString = ExcelString;
        this.WeightMultiplier = WeightMultiplier;
        this.Speed = Speed;
    }
}

export function Calculation_BFBInput_$reflection() {
    return record_type("BestFitBox.Domain.Model.Calculation.BFBInput", [], Calculation_BFBInput, () => [["FormData", class_type("Microsoft.FSharp.Collections.FSharpMap`2", [string_type, string_type])], ["Rows", list_type(Calculation_Row_$reflection())], ["NextRowIndex", int32_type], ["Containers", list_type(Calculation_Container_$reflection())], ["Item", Calculation_Item_$reflection()], ["Multiplier", Calculation_Multiplier_$reflection()], ["ExcelString", option_type(string_type)], ["WeightMultiplier", option_type(Calculation_Multiplier_$reflection())], ["Speed", option_type(Calculation_Speed_$reflection())]]);
}

export class Calculation_ContainerError extends Union {
    constructor(Item) {
        super();
        this.tag = 0;
        this.fields = [Item];
    }
    cases() {
        return ["InvalidContainer"];
    }
}

export function Calculation_ContainerError_$reflection() {
    return union_type("BestFitBox.Domain.Model.Calculation.ContainerError", [], Calculation_ContainerError, () => [[["Item", int32_type]]]);
}

export class Calculation_ContainerMode extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Single", "Multiple"];
    }
}

export function Calculation_ContainerMode_$reflection() {
    return union_type("BestFitBox.Domain.Model.Calculation.ContainerMode", [], Calculation_ContainerMode, () => [[], []]);
}

export class Calculation_CalcMode extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["MinimizeLength", "MinimizeHeight"];
    }
}

export function Calculation_CalcMode_$reflection() {
    return union_type("BestFitBox.Domain.Model.Calculation.CalcMode", [], Calculation_CalcMode, () => [[], []]);
}

export class Calculation_AllowStacking extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Allow", "Disallow"];
    }
}

export function Calculation_AllowStacking_$reflection() {
    return union_type("BestFitBox.Domain.Model.Calculation.AllowStacking", [], Calculation_AllowStacking, () => [[], []]);
}

export class Calculation_Tresholds extends Record {
    constructor(Left, Right, Top, Front, Back) {
        super();
        this.Left = Left;
        this.Right = Right;
        this.Top = Top;
        this.Front = Front;
        this.Back = Back;
    }
}

export function Calculation_Tresholds_$reflection() {
    return record_type("BestFitBox.Domain.Model.Calculation.Tresholds", [], Calculation_Tresholds, () => [["Left", Calculation_Dim_$reflection()], ["Right", Calculation_Dim_$reflection()], ["Top", Calculation_Dim_$reflection()], ["Front", Calculation_Dim_$reflection()], ["Back", Calculation_Dim_$reflection()]]);
}

export class Calculation_BinDrakeInput extends Record {
    constructor(FormData, Rows, NextRowIndex, Container, ContainerMode, Items, Multiplier, CalcMode, AllowStacking, WeightMultiplier, MaxMode, Speed, Tresholds) {
        super();
        this.FormData = FormData;
        this.Rows = Rows;
        this.NextRowIndex = (NextRowIndex | 0);
        this.Container = Container;
        this.ContainerMode = ContainerMode;
        this.Items = Items;
        this.Multiplier = Multiplier;
        this.CalcMode = CalcMode;
        this.AllowStacking = AllowStacking;
        this.WeightMultiplier = WeightMultiplier;
        this.MaxMode = MaxMode;
        this.Speed = Speed;
        this.Tresholds = Tresholds;
    }
}

export function Calculation_BinDrakeInput_$reflection() {
    return record_type("BestFitBox.Domain.Model.Calculation.BinDrakeInput", [], Calculation_BinDrakeInput, () => [["FormData", class_type("Microsoft.FSharp.Collections.FSharpMap`2", [string_type, string_type])], ["Rows", list_type(Calculation_Row_$reflection())], ["NextRowIndex", int32_type], ["Container", Calculation_Container_$reflection()], ["ContainerMode", Calculation_ContainerMode_$reflection()], ["Items", list_type(Calculation_Item_$reflection())], ["Multiplier", Calculation_Multiplier_$reflection()], ["CalcMode", Calculation_CalcMode_$reflection()], ["AllowStacking", option_type(Calculation_AllowStacking_$reflection())], ["WeightMultiplier", option_type(Calculation_Multiplier_$reflection())], ["MaxMode", option_type(bool_type)], ["Speed", option_type(Calculation_Speed_$reflection())], ["Tresholds", option_type(Calculation_Tresholds_$reflection())]]);
}

export function Calculation_BinDrakeInput_TryCreate_Z412DF242(container, items, multiplier, weightMultiplier, formData, rows, nextRowIndex, containerMode, calcMode, maxMode, tresholds, allowStacking, speed, containerUnitText) {
    const containerMultiplier = (containerUnitText === "ft") ? (12n) : ((containerUnitText === "in") ? (1n) : toInt64(fromDecimal(new Decimal(containerUnitText))));
    const containerDims = sortByDescending((x) => x, ofArray([Calculation_Dim__get_Value(container.LWH.Length), Calculation_Dim__get_Value(container.LWH.Width), Calculation_Dim__get_Value(container.LWH.Height)]), {
        Compare: compare,
    });
    const validatedItems = mapIndexed((itemIndex_3, item_3) => {
        let input_9;
        let input;
        const item = item_3;
        const itemDims = sortByDescending((x_2) => x_2, ofArray([Calculation_Dim__get_Value(item.LWH.Length), Calculation_Dim__get_Value(item.LWH.Width), Calculation_Dim__get_Value(item.LWH.Height)]), {
            Compare: compare,
        });
        input = ((((compare(item_5(2, itemDims), item_5(2, containerDims)) > 0) ? true : (compare(item_5(1, itemDims), item_5(1, containerDims)) > 0)) ? true : (compare(item_5(1, itemDims), item_5(1, containerDims)) > 0)) ? (new FSharpResult$2(1, [new Calculation_ContainerError(itemIndex_3)])) : (new FSharpResult$2(0, [undefined])));
        input_9 = ((input.tag === 1) ? (new FSharpResult$2(1, [singleton(input.fields[0])])) : (new FSharpResult$2(0, [input.fields[0]])));
        if (input_9.tag === 1) {
            return new FSharpResult$2(1, [input_9.fields[0]]);
        }
        else {
            let input_7;
            const input_1 = (Calculation_Weight__get_Value(item_3.Weight) > Calculation_Weight__get_Value(container.MaxWeight)) ? (new FSharpResult$2(1, [new Calculation_ContainerError(itemIndex_3)])) : (new FSharpResult$2(0, [undefined]));
            input_7 = ((input_1.tag === 1) ? (new FSharpResult$2(1, [singleton(input_1.fields[0])])) : (new FSharpResult$2(0, [input_1.fields[0]])));
            if (input_7.tag === 1) {
                return new FSharpResult$2(1, [input_7.fields[0]]);
            }
            else {
                let input_5;
                let input_2;
                const item_2 = item_3;
                input_2 = ((compare(toInt64(op_Multiply(toInt64(op_Multiply(Calculation_Dim__get_Value(item_2.LWH.Length), Calculation_Dim__get_Value(item_2.LWH.Width))), Calculation_Dim__get_Value(item_2.LWH.Height))), toInt64(op_Multiply(toInt64(op_Multiply(Calculation_Dim__get_Value(container.LWH.Length), Calculation_Dim__get_Value(container.LWH.Width))), Calculation_Dim__get_Value(container.LWH.Height)))) > 0) ? (new FSharpResult$2(1, [new Calculation_ContainerError(itemIndex_3)])) : (new FSharpResult$2(0, [undefined])));
                input_5 = ((input_2.tag === 1) ? (new FSharpResult$2(1, [singleton(input_2.fields[0])])) : (new FSharpResult$2(0, [input_2.fields[0]])));
                if (input_5.tag === 1) {
                    return new FSharpResult$2(1, [input_5.fields[0]]);
                }
                else {
                    return new FSharpResult$2(0, [undefined]);
                }
            }
        }
    }, items);
    const maxContainerDim = toInt64(op_Division_1(toInt64(op_Multiply(max_1(max_1(Calculation_Dim__get_Value(container.LWH.Length), Calculation_Dim__get_Value(container.LWH.Width)), Calculation_Dim__get_Value(container.LWH.Height)), containerMultiplier)), Calculation_Multiplier__get_Value(multiplier)));
    const invalidItem = tryFind((x_10) => {
        if (x_10.tag === 1) {
            return true;
        }
        else {
            return false;
        }
    }, validatedItems);
    let matchResult, errors;
    if (invalidItem != null) {
        const copyOfStruct = invalidItem;
        if (copyOfStruct.tag === 1) {
            matchResult = 0;
            errors = copyOfStruct.fields[0];
        }
        else {
            matchResult = 1;
        }
    }
    else {
        matchResult = 1;
    }
    switch (matchResult) {
        case 0: {
            const patternInput = head(errors);
            toConsole(printf("Error: %A"))(errors);
            return new FSharpResult$2(1, [new Calculation_DimError(1, [`Item ${patternInput.fields[0] + 1} is too large or too heavy for container`])]);
        }
        default:
            return new FSharpResult$2(0, [new Calculation_BinDrakeInput(formData, rows, nextRowIndex, container, containerMode, items, multiplier, calcMode, allowStacking, weightMultiplier, maxMode, speed, tresholds)]);
    }
}

export class Calculation_Coordinates extends Record {
    constructor(X, Y, Z) {
        super();
        this.X = X;
        this.Y = Y;
        this.Z = Z;
    }
}

export function Calculation_Coordinates_$reflection() {
    return record_type("BestFitBox.Domain.Model.Calculation.Coordinates", [], Calculation_Coordinates, () => [["X", int64_type], ["Y", int64_type], ["Z", int64_type]]);
}

export class Calculation_ItemPut extends Record {
    constructor(Item, Coordinates, ContainerIndex) {
        super();
        this.Item = Item;
        this.Coordinates = Coordinates;
        this.ContainerIndex = (ContainerIndex | 0);
    }
}

export function Calculation_ItemPut_$reflection() {
    return record_type("BestFitBox.Domain.Model.Calculation.ItemPut", [], Calculation_ItemPut, () => [["Item", Calculation_Item_$reflection()], ["Coordinates", Calculation_Coordinates_$reflection()], ["ContainerIndex", int32_type]]);
}

export class Calculation_BFBOutputRow extends Record {
    constructor(ItemsPut, Container, RemainingVolume) {
        super();
        this.ItemsPut = ItemsPut;
        this.Container = Container;
        this.RemainingVolume = RemainingVolume;
    }
}

export function Calculation_BFBOutputRow_$reflection() {
    return record_type("BestFitBox.Domain.Model.Calculation.BFBOutputRow", [], Calculation_BFBOutputRow, () => [["ItemsPut", list_type(Calculation_ItemPut_$reflection())], ["Container", string_type], ["RemainingVolume", int64_type]]);
}

export class Calculation_BFBOutput extends Record {
    constructor(OutputRows, Multiplier, CreditVersion, BTFInput, ResultKey) {
        super();
        this.OutputRows = OutputRows;
        this.Multiplier = Multiplier;
        this.CreditVersion = CreditVersion;
        this.BTFInput = BTFInput;
        this.ResultKey = ResultKey;
    }
}

export function Calculation_BFBOutput_$reflection() {
    return record_type("BestFitBox.Domain.Model.Calculation.BFBOutput", [], Calculation_BFBOutput, () => [["OutputRows", list_type(Calculation_BFBOutputRow_$reflection())], ["Multiplier", Calculation_Multiplier_$reflection()], ["CreditVersion", Version_$reflection()], ["BTFInput", Calculation_BFBInput_$reflection()], ["ResultKey", option_type(string_type)]]);
}

export class Calculation_BinDrakeOutputRow extends Record {
    constructor(ItemsPut, ItemsNotPut, Container) {
        super();
        this.ItemsPut = ItemsPut;
        this.ItemsNotPut = ItemsNotPut;
        this.Container = Container;
    }
}

export function Calculation_BinDrakeOutputRow_$reflection() {
    return record_type("BestFitBox.Domain.Model.Calculation.BinDrakeOutputRow", [], Calculation_BinDrakeOutputRow, () => [["ItemsPut", list_type(Calculation_ItemPut_$reflection())], ["ItemsNotPut", list_type(Calculation_Item_$reflection())], ["Container", Calculation_Container_$reflection()]]);
}

export class Calculation_BinDrakeOutput extends Record {
    constructor(OutputRows, Multiplier, BinDrakeInput, CreditVersion, ResultKey) {
        super();
        this.OutputRows = OutputRows;
        this.Multiplier = Multiplier;
        this.BinDrakeInput = BinDrakeInput;
        this.CreditVersion = CreditVersion;
        this.ResultKey = ResultKey;
    }
}

export function Calculation_BinDrakeOutput_$reflection() {
    return record_type("BestFitBox.Domain.Model.Calculation.BinDrakeOutput", [], Calculation_BinDrakeOutput, () => [["OutputRows", list_type(Calculation_BinDrakeOutputRow_$reflection())], ["Multiplier", Calculation_Multiplier_$reflection()], ["BinDrakeInput", Calculation_BinDrakeInput_$reflection()], ["CreditVersion", Version_$reflection()], ["ResultKey", option_type(string_type)]]);
}

export class Calculation_Error extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["InvalidContainer", "InvalidItem", "InvalidInput", "NotSubscribed", "OtherError"];
    }
}

export function Calculation_Error_$reflection() {
    return union_type("BestFitBox.Domain.Model.Calculation.Error", [], Calculation_Error, () => [[], [], [], [["Item", string_type]], [["Item", string_type]]]);
}

export class Calculation_CalculationId extends Union {
    constructor(Item) {
        super();
        this.tag = 0;
        this.fields = [Item];
    }
    cases() {
        return ["CalculationId"];
    }
}

export function Calculation_CalculationId_$reflection() {
    return union_type("BestFitBox.Domain.Model.Calculation.CalculationId", [], Calculation_CalculationId, () => [[["Item", ShortString_$reflection()]]]);
}

export function Calculation_CalculationId__get_Value(this$) {
    return ShortString__get_Value(this$.fields[0]);
}

export class Calculation_CalculationData extends Union {
    constructor(Item) {
        super();
        this.tag = 0;
        this.fields = [Item];
    }
    cases() {
        return ["CalculationData"];
    }
}

export function Calculation_CalculationData_$reflection() {
    return union_type("BestFitBox.Domain.Model.Calculation.CalculationData", [], Calculation_CalculationData, () => [[["Item", LongString_$reflection()]]]);
}

export function Calculation_CalculationData__get_Value(this$) {
    return LongString__get_Value(this$.fields[0]);
}

export class Calculation_CalculationHistoryData extends Record {
    constructor(CalculationId, UserId, Version, CalculationDate, ActualUserId, Title) {
        super();
        this.CalculationId = CalculationId;
        this.UserId = UserId;
        this.Version = Version;
        this.CalculationDate = CalculationDate;
        this.ActualUserId = ActualUserId;
        this.Title = Title;
    }
}

export function Calculation_CalculationHistoryData_$reflection() {
    return record_type("BestFitBox.Domain.Model.Calculation.CalculationHistoryData", [], Calculation_CalculationHistoryData, () => [["CalculationId", Calculation_CalculationId_$reflection()], ["UserId", Authentication_Email_$reflection()], ["Version", Version_$reflection()], ["CalculationDate", class_type("System.DateTime")], ["ActualUserId", option_type(string_type)], ["Title", option_type(string_type)]]);
}

export class Calculation_BulkUploadFinalResultRow extends Record {
    constructor(ItemId, ContainerQuantity, BestFit, BestFitQuantity, BestFitWeight, RemainingVolume, CalculationId) {
        super();
        this.ItemId = ItemId;
        this.ContainerQuantity = ContainerQuantity;
        this.BestFit = BestFit;
        this.BestFitQuantity = (BestFitQuantity | 0);
        this.BestFitWeight = BestFitWeight;
        this.RemainingVolume = RemainingVolume;
        this.CalculationId = CalculationId;
    }
}

export function Calculation_BulkUploadFinalResultRow_$reflection() {
    return record_type("BestFitBox.Domain.Model.Calculation.BulkUploadFinalResultRow", [], Calculation_BulkUploadFinalResultRow, () => [["ItemId", string_type], ["ContainerQuantity", class_type("Microsoft.FSharp.Collections.FSharpMap`2", [string_type, int32_type])], ["BestFit", string_type], ["BestFitQuantity", int32_type], ["BestFitWeight", decimal_type], ["RemainingVolume", decimal_type], ["CalculationId", string_type]]);
}

export class Calculation_BulkUploadFinalResult extends Record {
    constructor(CalculationId, UserId, OutputRows) {
        super();
        this.CalculationId = CalculationId;
        this.UserId = UserId;
        this.OutputRows = OutputRows;
    }
}

export function Calculation_BulkUploadFinalResult_$reflection() {
    return record_type("BestFitBox.Domain.Model.Calculation.BulkUploadFinalResult", [], Calculation_BulkUploadFinalResult, () => [["CalculationId", Calculation_CalculationId_$reflection()], ["UserId", Authentication_Email_$reflection()], ["OutputRows", list_type(Calculation_BulkUploadFinalResultRow_$reflection())]]);
}

export class Calculation_BulkUpload extends Record {
    constructor(CalculationId, UserId, Version, CreateDate, UpdatedDate, Status) {
        super();
        this.CalculationId = CalculationId;
        this.UserId = UserId;
        this.Version = Version;
        this.CreateDate = CreateDate;
        this.UpdatedDate = UpdatedDate;
        this.Status = Status;
    }
}

export function Calculation_BulkUpload_$reflection() {
    return record_type("BestFitBox.Domain.Model.Calculation.BulkUpload", [], Calculation_BulkUpload, () => [["CalculationId", Calculation_CalculationId_$reflection()], ["UserId", Authentication_Email_$reflection()], ["Version", Version_$reflection()], ["CreateDate", class_type("System.DateTime")], ["UpdatedDate", class_type("System.DateTime")], ["Status", string_type]]);
}

export class Calculation_BulkUploadData extends Record {
    constructor(CalculationId, FinalResult) {
        super();
        this.CalculationId = CalculationId;
        this.FinalResult = FinalResult;
    }
}

export function Calculation_BulkUploadData_$reflection() {
    return record_type("BestFitBox.Domain.Model.Calculation.BulkUploadData", [], Calculation_BulkUploadData, () => [["CalculationId", Calculation_CalculationId_$reflection()], ["FinalResult", Calculation_BulkUploadFinalResult_$reflection()]]);
}

export class CreditSystem_Credit extends Union {
    constructor(Item) {
        super();
        this.tag = 0;
        this.fields = [Item];
    }
    cases() {
        return ["Credit"];
    }
}

export function CreditSystem_Credit_$reflection() {
    return union_type("BestFitBox.Domain.Model.CreditSystem.Credit", [], CreditSystem_Credit, () => [[["Item", int64_type]]]);
}

export function CreditSystem_Credit__get_Value(this$) {
    return this$.fields[0];
}

export class CreditSystem_CreditData extends Record {
    constructor(Credit, UserId, Version, UpdatedDate) {
        super();
        this.Credit = Credit;
        this.UserId = UserId;
        this.Version = Version;
        this.UpdatedDate = UpdatedDate;
    }
}

export function CreditSystem_CreditData_$reflection() {
    return record_type("BestFitBox.Domain.Model.CreditSystem.CreditData", [], CreditSystem_CreditData, () => [["Credit", CreditSystem_Credit_$reflection()], ["UserId", Authentication_Email_$reflection()], ["Version", Version_$reflection()], ["UpdatedDate", class_type("System.DateTime")]]);
}

export class CreditSystem_Subscription extends Record {
    constructor(CustomerId, SubscriptionId, SubscriptionActive, SubscriptionStartDate, SubscriptionEndDate, UserId) {
        super();
        this.CustomerId = CustomerId;
        this.SubscriptionId = SubscriptionId;
        this.SubscriptionActive = SubscriptionActive;
        this.SubscriptionStartDate = SubscriptionStartDate;
        this.SubscriptionEndDate = SubscriptionEndDate;
        this.UserId = UserId;
    }
}

export function CreditSystem_Subscription_$reflection() {
    return record_type("BestFitBox.Domain.Model.CreditSystem.Subscription", [], CreditSystem_Subscription, () => [["CustomerId", string_type], ["SubscriptionId", string_type], ["SubscriptionActive", bool_type], ["SubscriptionStartDate", class_type("System.DateTime")], ["SubscriptionEndDate", option_type(class_type("System.DateTime"))], ["UserId", Authentication_Email_$reflection()]]);
}

export class Application_OnlineState extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Online", "Offline"];
    }
}

export function Application_OnlineState_$reflection() {
    return union_type("BestFitBox.Domain.Model.Application.OnlineState", [], Application_OnlineState, () => [[], []]);
}

