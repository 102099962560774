import { isMatch, create } from "../fable_modules/fable-library-js.4.20.0/RegExp.js";
import { toString, Record, Union } from "../fable_modules/fable-library-js.4.20.0/Types.js";
import { class_type, record_type, string_type, union_type } from "../fable_modules/fable-library-js.4.20.0/Reflection.js";
import { FSharpMap__get_Item, add, empty } from "../fable_modules/fable-library-js.4.20.0/Map.js";
import { structuralHash, equals, int32ToString, compare, defaultOf, comparePrimitives } from "../fable_modules/fable-library-js.4.20.0/Util.js";
import { contains, fold, empty as empty_1, singleton } from "../fable_modules/fable-library-js.4.20.0/List.js";
import { singleton as singleton_1 } from "../fable_modules/fable-library-js.4.20.0/AsyncBuilder.js";
import { value as value_4, some } from "../fable_modules/fable-library-js.4.20.0/Option.js";
import { FSharpResult$2 } from "../fable_modules/fable-library-js.4.20.0/Result.js";
import { replace, printf, toConsole, isNullOrWhiteSpace } from "../fable_modules/fable-library-js.4.20.0/String.js";
import { forAll, length } from "../fable_modules/fable-library-js.4.20.0/Seq.js";

export const ValidateRegexes_mail = create("", 0);

export const ValidateRegexes_url = create("", 0);

export class ValidateResult$1 extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Valid", "Invalid"];
    }
}

export function ValidateResult$1_$reflection(gen0) {
    return union_type("Fable.Validation.ValidateResult`1", [gen0], ValidateResult$1, () => [[["Item", gen0]], []]);
}

export function ValidateResult$1__get_IsValid_(x) {
    if (x.tag === 0) {
        return true;
    }
    else {
        return false;
    }
}

export function ValidateResult$1__get_IsInvalid_(x) {
    return !ValidateResult$1__get_IsValid_(x);
}

export class FieldInfo$3 extends Record {
    constructor(key, original, result, validator) {
        super();
        this.key = key;
        this.original = original;
        this.result = result;
        this.validator = validator;
    }
}

export function FieldInfo$3_$reflection(gen0, gen1, gen2) {
    return record_type("Fable.Validation.FieldInfo`3", [gen0, gen1, gen2], FieldInfo$3, () => [["key", string_type], ["original", gen0], ["result", ValidateResult$1_$reflection(gen1)], ["validator", Validator$1_$reflection(gen2)]]);
}

export class Validator$1 {
    constructor(all) {
        this.all = all;
        this.errors = empty({
            Compare: comparePrimitives,
        });
        this.hasError = false;
    }
}

export function Validator$1_$reflection(gen0) {
    return class_type("Fable.Validation.Validator`1", [gen0], Validator$1);
}

export function Validator$1_$ctor_Z1FBCCD16(all) {
    return new Validator$1(all);
}

export function FieldInfo$3__Replace_Z6360F424(x, result) {
    return new FieldInfo$3(x.key, x.original, result, x.validator);
}

export function Validator$1__get_HasError(__) {
    return __.hasError;
}

export function Validator$1__get_Errors(__) {
    return __.errors;
}

export function Validator$1__PushError(__, name, error) {
    if (!__.hasError) {
        __.hasError = true;
    }
    __.errors = add(name, singleton(error), __.errors);
}

export function Validator$1__Test(x, name, value) {
    x.errors = add(name, empty_1(), x.errors);
    if (!x.all && x.hasError) {
        return new FieldInfo$3(name, value, new ValidateResult$1(1, []), x);
    }
    else {
        return new FieldInfo$3(name, value, new ValidateResult$1(0, [value]), x);
    }
}

export function Validator$1__End_Z2CAB7B75(__, input) {
    const matchValue = input.result;
    if (matchValue.tag === 0) {
        return matchValue.fields[0];
    }
    else {
        return defaultOf();
    }
}

export function Validator$1__EndAsync_2D078A07(x, input) {
    return singleton_1.Delay(() => singleton_1.Bind(input, (_arg) => singleton_1.Return(Validator$1__End_Z2CAB7B75(x, _arg))));
}

/**
 * Test rules only if value is Some,
 * it won't collect error if value is None
 */
export function Validator$1__TestOnlySome(t, name, value, rules) {
    if (value == null) {
        return undefined;
    }
    else {
        return ((arg) => some(Validator$1__End_Z2CAB7B75(t, arg)))(fold((acc, f) => f(acc), Validator$1__Test(t, name, value_4(value)), rules));
    }
}

/**
 * Test rules only if value is Ok,
 * it won't collect error if value is Error
 */
export function Validator$1__TestOnlyOk(t, name, value, rules) {
    if (value.tag === 1) {
        return new FSharpResult$2(1, [value.fields[0]]);
    }
    else {
        return ((arg) => (new FSharpResult$2(0, [Validator$1__End_Z2CAB7B75(t, arg)])))(fold((acc, f) => f(acc), Validator$1__Test(t, name, value.fields[0]), rules));
    }
}

/**
 * Test rules only if value is Some,
 * it won't collect error if value is None
 */
export function Validator$1__TestOnlySomeAsync(t, name, value, rules) {
    return singleton_1.Delay(() => {
        let info;
        if (value == null) {
            return singleton_1.Return(undefined);
        }
        else {
            const value_1 = value_4(value);
            return singleton_1.ReturnFrom((info = singleton_1.Delay(() => singleton_1.Return(Validator$1__Test(t, name, value_1))), singleton_1.Delay(() => singleton_1.Bind(fold((acc, f) => f(acc), info, rules), (_arg) => singleton_1.Return(((arg) => some(Validator$1__End_Z2CAB7B75(t, arg)))(_arg))))));
        }
    });
}

/**
 * Test rules only if value is Ok,
 * it won't collect error if value is Error
 */
export function Validator$1__TestOnlyOkAsync(t, name, value, rules) {
    return singleton_1.Delay(() => {
        let info;
        return (value.tag === 1) ? singleton_1.Return(new FSharpResult$2(1, [value.fields[0]])) : singleton_1.ReturnFrom((info = singleton_1.Delay(() => singleton_1.Return(Validator$1__Test(t, name, value.fields[0]))), singleton_1.Delay(() => singleton_1.Bind(fold((acc, f) => f(acc), info, rules), (_arg) => singleton_1.Return(((arg) => (new FSharpResult$2(0, [Validator$1__End_Z2CAB7B75(t, arg)])))(_arg))))));
    });
}

/**
 * Validate with a custom tester, return ValidateResult DU to modify input value
 */
export function Validator$1__IsValidOpt(__, tester, error, input) {
    const matchValue = input.result;
    if (matchValue.tag === 0) {
        const result = tester(matchValue.fields[0]);
        if (ValidateResult$1__get_IsInvalid_(result)) {
            Validator$1__PushError(input.validator, input.key, error);
        }
        return FieldInfo$3__Replace_Z6360F424(input, result);
    }
    else {
        return FieldInfo$3__Replace_Z6360F424(input, new ValidateResult$1(1, []));
    }
}

/**
 * Validate with a custom tester, return bool
 */
export function Validator$1__IsValid_Z4CF01147(x, tester) {
    return (error) => ((input) => Validator$1__IsValidOpt(x, (v) => {
        if (tester(v)) {
            return new ValidateResult$1(0, [v]);
        }
        else {
            return new ValidateResult$1(1, []);
        }
    }, error, input));
}

export function Validator$1__IsValidOptAsync(__, tester, error, input) {
    return singleton_1.Delay(() => singleton_1.Bind(input, (_arg) => {
        const input_1 = _arg;
        const matchValue = input_1.result;
        return (matchValue.tag === 1) ? singleton_1.Return(FieldInfo$3__Replace_Z6360F424(input_1, new ValidateResult$1(1, []))) : singleton_1.Bind(tester(matchValue.fields[0]), (_arg_1) => {
            const result = _arg_1;
            return singleton_1.Combine(ValidateResult$1__get_IsInvalid_(result) ? ((Validator$1__PushError(input_1.validator, input_1.key, error), singleton_1.Zero())) : singleton_1.Zero(), singleton_1.Delay(() => singleton_1.Return(FieldInfo$3__Replace_Z6360F424(input_1, result))));
        });
    }));
}

export function Validator$1__IsValidAsync_339E6CF5(x, tester) {
    return (error) => ((input) => Validator$1__IsValidOptAsync(x, (v) => singleton_1.Delay(() => singleton_1.Bind(tester(v), (_arg) => singleton_1.Return(_arg ? (new ValidateResult$1(0, [v])) : (new ValidateResult$1(1, []))))), error, input));
}

export function Validator$1__Trim_Z771C645(__, input) {
    const matchValue = input.result;
    if (matchValue.tag === 1) {
        return input;
    }
    else {
        return FieldInfo$3__Replace_Z6360F424(input, new ValidateResult$1(0, [matchValue.fields[0].trim()]));
    }
}

/**
 * Validate with `String.IsNullOrWhiteSpace`
 */
export function Validator$1__NotBlank_2B595(x, err) {
    return Validator$1__IsValid_Z4CF01147(x, (arg) => !isNullOrWhiteSpace(arg))(err);
}

/**
 * Test an option value is some and unwrap it
 * it will collect error
 */
export function Validator$1__IsSome_2B595(x, error) {
    return (input) => Validator$1__IsValidOpt(x, (i) => {
        if (i != null) {
            return new ValidateResult$1(0, [value_4(i)]);
        }
        else {
            return new ValidateResult$1(1, []);
        }
    }, error, input);
}

/**
 * Defaults of None value, it won't collect error
 */
export function Validator$1__DefaultOfNone(__, defaults, input) {
    const matchValue = input.result;
    let matchResult, value;
    if (matchValue.tag === 0) {
        if (matchValue.fields[0] != null) {
            matchResult = 0;
            value = value_4(matchValue.fields[0]);
        }
        else {
            matchResult = 1;
        }
    }
    else {
        matchResult = 1;
    }
    switch (matchResult) {
        case 0:
            return FieldInfo$3__Replace_Z6360F424(input, new ValidateResult$1(0, [value]));
        default:
            return FieldInfo$3__Replace_Z6360F424(input, new ValidateResult$1(0, [defaults]));
    }
}

/**
 * Test a Result value is Ok and unwrap it
 * it will collect error
 */
export function Validator$1__IsOk_2B595(x, error) {
    return (input) => Validator$1__IsValidOpt(x, (i) => {
        if (i.tag === 0) {
            return new ValidateResult$1(0, [i.fields[0]]);
        }
        else {
            return new ValidateResult$1(1, []);
        }
    }, error, input);
}

/**
 * Defaults of Error value, it won't collect error
 */
export function Validator$1__DefaultOfError(__, defaults, input) {
    const matchValue = input.result;
    let matchResult, value;
    if (matchValue.tag === 0) {
        if (matchValue.fields[0].tag === 0) {
            matchResult = 0;
            value = matchValue.fields[0].fields[0];
        }
        else {
            matchResult = 1;
        }
    }
    else {
        matchResult = 1;
    }
    switch (matchResult) {
        case 0:
            return FieldInfo$3__Replace_Z6360F424(input, new ValidateResult$1(0, [value]));
        default:
            return FieldInfo$3__Replace_Z6360F424(input, new ValidateResult$1(0, [defaults]));
    }
}

/**
 * Map a function or constructor to the value, aka lift
 * fn shouldn't throw error, if it would, please using `t.To fn error`
 */
export function Validator$1__Map_7C4B0DD6(x, fn) {
    const error = defaultOf();
    return (input) => Validator$1__IsValidOpt(x, (arg) => (new ValidateResult$1(0, [fn(arg)])), error, input);
}

/**
 * Convert the input value by fn
 * if fn throws error then it will collect error
 */
export function Validator$1__To_7C4B0DD6(x, fn) {
    return (error) => ((input) => Validator$1__IsValidOpt(x, (t) => {
        try {
            return new ValidateResult$1(0, [fn(t)]);
        }
        catch (exn) {
            const arg_2 = exn.message;
            const arg_3 = exn.stack;
            toConsole(printf("Validation Map error: \nfn: %A \nvalue: %A \nException: %s %s"))(fn)(t)(arg_2)(arg_3);
            return new ValidateResult$1(1, []);
        }
    }, error, input));
}

/**
 * Convert a synchronize validate pipe to asynchronize
 */
export function Validator$1__ToAsync_Z2CAB7B75(__, input) {
    return singleton_1.Delay(() => singleton_1.Return(input));
}

/**
 * Greater then a value, if err is a string, it can contains `{min}` to reuse first param
 */
export function Validator$1__Gt(x, min, err) {
    let strErr, copyOfStruct;
    let err_1;
    const matchValue = err;
    err_1 = ((typeof matchValue === "string") ? ((strErr = matchValue, replace(strErr, "{min}", (copyOfStruct = min, toString(copyOfStruct))))) : err);
    return Validator$1__IsValid_Z4CF01147(x, (input) => (compare(input, min) > 0))(err_1);
}

/**
 * Greater and equal then a value, if err is a string, it can contains `{min}` to reuse first param
 */
export function Validator$1__Gte(x, min, err) {
    let strErr, copyOfStruct;
    let err_1;
    const matchValue = err;
    err_1 = ((typeof matchValue === "string") ? ((strErr = matchValue, replace(strErr, "{min}", (copyOfStruct = min, toString(copyOfStruct))))) : err);
    return Validator$1__IsValid_Z4CF01147(x, (input) => (compare(input, min) >= 0))(err_1);
}

/**
 * Less then a value, if err is a string, it can contains `{max}` to reuse first param
 */
export function Validator$1__Lt(x, max, err) {
    let strErr, copyOfStruct;
    let err_1;
    const matchValue = err;
    err_1 = ((typeof matchValue === "string") ? ((strErr = matchValue, replace(strErr, "{max}", (copyOfStruct = max, toString(copyOfStruct))))) : err);
    return Validator$1__IsValid_Z4CF01147(x, (input) => (compare(input, max) < 0))(err_1);
}

/**
 * Less and equal then a value, if err is a string, it can contains `{max}` to reuse first param
 */
export function Validator$1__Lte(x, max, err) {
    let strErr, copyOfStruct;
    let err_1;
    const matchValue = err;
    err_1 = ((typeof matchValue === "string") ? ((strErr = matchValue, replace(strErr, "{max}", (copyOfStruct = max, toString(copyOfStruct))))) : err);
    return Validator$1__IsValid_Z4CF01147(x, (input) => (compare(input, max) <= 0))(err_1);
}

/**
 * Max length, if err is a string, it can contains `{len}` to reuse first param
 */
export function Validator$1__MaxLen(x, len, err, input) {
    let strErr, copyOfStruct;
    let err_1;
    const matchValue = err;
    err_1 = ((typeof matchValue === "string") ? ((strErr = matchValue, replace(strErr, "{len}", (copyOfStruct = len, int32ToString(copyOfStruct))))) : err);
    return Validator$1__IsValid_Z4CF01147(x, (input_1) => (length(input_1) <= len))(err_1)(input);
}

/**
 * Min length, if err is a string, it can contains `{len}` to reuse first param
 */
export function Validator$1__MinLen(x, len, err, input) {
    let strErr, copyOfStruct;
    let err_1;
    const matchValue = err;
    err_1 = ((typeof matchValue === "string") ? ((strErr = matchValue, replace(strErr, "{len}", (copyOfStruct = len, int32ToString(copyOfStruct))))) : err);
    return Validator$1__IsValid_Z4CF01147(x, (input_1) => (length(input_1) >= len))(err_1)(input);
}

export function Validator$1__Enum_Z4E64C11(x, enums) {
    return Validator$1__IsValid_Z4CF01147(x, (input) => contains(input, enums, {
        Equals: equals,
        GetHashCode: structuralHash,
    }));
}

export function Validator$1__IsMail(x, error, input) {
    return Validator$1__IsValid_Z4CF01147(x, (input_1) => isMatch(ValidateRegexes_mail, input_1))(error)(input);
}

export function Validator$1__IsUrl(x, error, input) {
    return Validator$1__IsValid_Z4CF01147(x, (input_1) => isMatch(ValidateRegexes_url, input_1))(error)(input);
}

export function Validator$1__Match(x, regex, error, input) {
    return Validator$1__IsValid_Z4CF01147(x, (input_1) => isMatch(regex, input_1))(error)(input);
}

export function Validator$1__IsDegist(x, error, input) {
    return Validator$1__IsValid_Z4CF01147(x, (str) => forAll((c) => {
        if (c >= "0") {
            return c <= "9";
        }
        else {
            return false;
        }
    }, str.split("")))(error)(input);
}

function instance() {
    return Validator$1_$ctor_Z1FBCCD16(true);
}

/**
 * IsValid helper from Validator method for custom rule functions, you can also extend Validator class directly.
 */
export function isValid() {
    return (tester) => Validator$1__IsValid_Z4CF01147(instance(), tester);
}

/**
 * IsValidOpt helper from Validator method for custom rule functions, you can also extend Validator class directly.
 */
export function isValidOpt() {
    return (tester) => ((error) => ((input) => Validator$1__IsValidOpt(instance(), tester, error, input)));
}

/**
 * IsValidAsync helper from Validator method for custom rule functions, you can also extend Validator class directly.
 */
export function isValidAsync() {
    return (tester) => Validator$1__IsValidAsync_339E6CF5(instance(), tester);
}

/**
 * IsValidOptAsync helper from Validator method for custom rule functions, you can also extend Validator class directly.
 */
export function isValidOptAsync() {
    return (tester) => ((error) => ((input) => Validator$1__IsValidOptAsync(instance(), tester, error, input)));
}

export function validateSync(all, tester) {
    const validator = Validator$1_$ctor_Z1FBCCD16(all);
    const ret = tester(validator);
    if (Validator$1__get_HasError(validator)) {
        return new FSharpResult$2(1, [Validator$1__get_Errors(validator)]);
    }
    else {
        return new FSharpResult$2(0, [ret]);
    }
}

export function validateAsync(all, tester) {
    return singleton_1.Delay(() => {
        const validator = Validator$1_$ctor_Z1FBCCD16(all);
        return singleton_1.Bind(tester(validator), (_arg) => (Validator$1__get_HasError(validator) ? singleton_1.Return(new FSharpResult$2(1, [Validator$1__get_Errors(validator)])) : singleton_1.Return(new FSharpResult$2(0, [_arg]))));
    });
}

/**
 * Validate single value
 */
export function single(tester) {
    const t = Validator$1_$ctor_Z1FBCCD16(true);
    const ret = tester(t);
    if (Validator$1__get_HasError(t)) {
        return new FSharpResult$2(1, [FSharpMap__get_Item(Validator$1__get_Errors(t), "s")]);
    }
    else {
        return new FSharpResult$2(0, [ret]);
    }
}

/**
 * Validate single value asynchronize
 */
export function singleAsync(tester) {
    return singleton_1.Delay(() => {
        const t = Validator$1_$ctor_Z1FBCCD16(true);
        return singleton_1.Bind(tester(t), (_arg) => (Validator$1__get_HasError(t) ? singleton_1.Return(new FSharpResult$2(1, [FSharpMap__get_Item(Validator$1__get_Errors(t), "s")])) : singleton_1.Return(new FSharpResult$2(0, [_arg]))));
    });
}

