import { Hook_createDisposable_3A5B6456, HookComponentAttribute } from "./fable_modules/Fable.Lit.1.4.2/Hook.fs.js";
import { Hook_getContext_343DAFF1, Hook_useHmr_ZF11B4D8 } from "./fable_modules/Fable.Lit.1.4.2/Hook.fs.js";
import { update, init, Mode, Msg } from "./MVU/ManualEntry.js";
import { Lit_get_nothing, LitHelpers_html } from "./fable_modules/Fable.Lit.1.4.2/Lit.fs.js";
import { join, fmt } from "./fable_modules/fable-library-js.4.20.0/String.js";
import { Prop_Of_Z4A92C983, LitElementAttribute } from "./fable_modules/Fable.Lit.1.4.2/LitElement.fs.js";
import { cleanDSL } from "./ViewFunctions.js";
import { defaultOf } from "./fable_modules/fable-library-js.4.20.0/Util.js";
import { Authentication_Email_TryCreate_Z721C83C5 } from "./Domain/Model.js";
import { map } from "./fable_modules/fable-library-js.4.20.0/List.js";
import { toString } from "./fable_modules/fable-library-js.4.20.0/Types.js";
import { parse } from "./fable_modules/fable-library-js.4.20.0/Boolean.js";
import { Program_mkHiddenProgramWithOrderExecute } from "./ElmishOrder.js";
import { LitElmishExtensions_useElmish } from "./fable_modules/Fable.Lit.Elmish.1.4.0/Lit.Elmish.fs.js";

const hmr = undefined;

export function execute(_host, order, dispatch) {
    switch (order.tag) {
        case 1: {
            const bindrakeInputForm = document.querySelector("bindrake-input-form");
            const value = bindrakeInputForm.removeAttribute("aimode");
            const bindrakeFillFromAI = document.querySelector("bindrake-fillfromai");
            bindrakeFillFromAI.style.display = "none";
            break;
        }
        case 2: {
            const bindrakeInputForm_1 = document.querySelector("bindrake-input-form");
            const value_1 = bindrakeInputForm_1.setAttribute("aimode", "");
            const bindrakeFillFromAI_1 = document.querySelector("bindrake-fillfromai");
            bindrakeFillFromAI_1.style.display = "";
            break;
        }
        default:
            undefined;
    }
}

export const view = (new HookComponentAttribute()).Decorate(function (host, model, dispatch) {
    Hook_useHmr_ZF11B4D8(hmr, this);
    Hook_getContext_343DAFF1(this).useEffectOnce(() => {
        const loginHandler = (e) => {
            const customEvent = e;
            dispatch(new Msg(0, [customEvent.detail]));
        };
        document.addEventListener("loggedOut", loginHandler);
        document.addEventListener("loggedIn", loginHandler);
        return Hook_createDisposable_3A5B6456(() => {
            document.removeEventListener("loggedIn", loginHandler);
            document.removeEventListener("loggedOut", (e_1) => {
                dispatch(new Msg(1, []));
            });
        });
    });
    const matchValue = model.User;
    if (matchValue != null) {
        const user = matchValue;
        return (model.Mode.tag === 1) ? LitHelpers_html(fmt`<button @click='${(_arg_1) => {
            dispatch(new Msg(2, [new Mode(0, [])]));
        }}'>AI Mode</button>`) : LitHelpers_html(fmt`<button @click='${(_arg) => {
            dispatch(new Msg(2, [new Mode(1, [])]));
        }}'>Manual Entry</button>`);
    }
    else {
        return Lit_get_nothing();
    }
});

export const UserAndCredit = (new LitElementAttribute("bindrake-manual-entry")).Decorate(function () {
    let e_1;
    Hook_useHmr_ZF11B4D8(hmr, this);
    const patternInput = (this).init((arg) => {
        let a;
        const config = arg;
        config.useShadowDom = true;
        a = (config.props = {
            subscriber: Prop_Of_Z4A92C983("false"),
        });
        return Promise.resolve(undefined);
    });
    const host = patternInput[0];
    cleanDSL(host);
    const userAttribute = document.querySelector("bfb-login").getAttribute("user");
    const userId = (userAttribute === defaultOf()) ? undefined : ((userAttribute === "") ? undefined : ((e_1 = Authentication_Email_TryCreate_Z721C83C5(userAttribute), (e_1.tag === 1) ? (() => {
        throw new Error(join(", ", map((x_2) => {
            let copyOfStruct = x_2;
            return toString(copyOfStruct);
        }, e_1.fields[0])));
    })() : e_1.fields[0])));
    const subscriber = parse(patternInput[1].subscriber);
    const program = Program_mkHiddenProgramWithOrderExecute(() => init(userId, subscriber, undefined), update, (order, dispatch) => {
        execute(host, order, dispatch);
    });
    const patternInput_1 = LitElmishExtensions_useElmish(Hook_getContext_343DAFF1(this), () => program);
    return view(host, patternInput_1[0], patternInput_1[1]);
});

export function register() {
}

