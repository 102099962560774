import { parse } from "./fable_modules/fable-library-js.4.20.0/Int32.js";
import { equals, defaultOf } from "./fable_modules/fable-library-js.4.20.0/Util.js";
import { unsafeHTML } from "lit/directives/unsafe-html.js";
import { printf, toText } from "./fable_modules/fable-library-js.4.20.0/String.js";
import { item } from "./fable_modules/fable-library-js.4.20.0/Array.js";
import { singleton } from "./fable_modules/fable-library-js.4.20.0/AsyncBuilder.js";
import { some, value as value_2 } from "./fable_modules/fable-library-js.4.20.0/Option.js";
import { sleep } from "./fable_modules/fable-library-js.4.20.0/Async.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "./fable_modules/Fable.Promise.3.2.0/Promise.fs.js";
import { promise } from "./fable_modules/Fable.Promise.3.2.0/PromiseImpl.fs.js";
import { toString } from "./fable_modules/fable-library-js.4.20.0/Types.js";

export function baseLevel(host) {
    const l = host.getAttribute("data-level");
    let matchResult;
    if (l === defaultOf()) {
        matchResult = 0;
    }
    else if (l === "") {
        matchResult = 0;
    }
    else {
        matchResult = 1;
    }
    switch (matchResult) {
        case 0:
            return 0;
        default:
            return parse(l, 511, false, 32) | 0;
    }
}

export function heading(baseLevel_1, level, attributes, children) {
    return unsafeHTML(`<h${level + baseLevel_1} ${attributes}>${children}</h${level + baseLevel_1}>`);
}

export function cleanDSL(host) {
    if (!host.afterFirstRun) {
        host.afterFirstRun = true;
        const children = host.shadowRoot.querySelectorAll(".DSL");
        for (let i = 0; i <= (children.length - 1); i++) {
            (children[i]).remove();
        }
    }
}

export function hslToHex(h, s, l) {
    const c = (1 - Math.abs((2 * l) - 1)) * s;
    const x = c * (1 - Math.abs(((h / 60) % 2) - 1));
    const m = l - (c / 2);
    const patternInput = (h < 60) ? [c, x, 0] : ((h < 120) ? [x, c, 0] : ((h < 180) ? [0, c, x] : ((h < 240) ? [0, x, c] : ((h < 300) ? [x, 0, c] : [c, 0, x]))));
    const arg = ~~((patternInput[0] + m) * 255) | 0;
    const arg_1 = ~~((patternInput[1] + m) * 255) | 0;
    const arg_2 = ~~((patternInput[2] + m) * 255) | 0;
    return toText(printf("#%02X%02X%02X"))(arg)(arg_1)(arg_2);
}

export function changed() {
    return !equals(item(0, arguments), item(1, arguments));
}

export function retry(f) {
    const retryInner = (attempt) => singleton.Delay(() => ((attempt > 7) ? singleton.Return(undefined) : singleton.Bind(f(), (_arg) => {
        if (_arg != null) {
            const res = value_2(_arg);
            return singleton.Return(some(res));
        }
        else {
            return singleton.Bind(sleep(100 * attempt), () => singleton.ReturnFrom(retryInner(attempt * 2)));
        }
    })));
    return retryInner(3);
}

export function changeBrowserUrl(newUrl, stateObj, replace) {
    const currentTitle = document.title;
    if (replace) {
        window.history.replaceState(stateObj, currentTitle, newUrl);
    }
    else {
        window.history.pushState(stateObj, currentTitle, newUrl);
    }
}

export function copyTextToClipboard(text) {
    const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const clipboard = navigator.clipboard;
        return (clipboard.writeText(text)).then((_arg) => (Promise.resolve(undefined)));
    }));
    void (pr.then(() => {
    }));
}

export function changeUrlQueryParams(key, value, replace) {
    const urlInstance = new URL(window.location.href);
    const searchParams = urlInstance.searchParams;
    searchParams.set(key, value);
    changeBrowserUrl(urlInstance.toString(), {}, replace);
}

export function deleteUrlQueryParams(key, replace) {
    const urlInstance = new URL(window.location.href);
    const searchParams = urlInstance.searchParams;
    searchParams.delete(key);
    changeBrowserUrl(urlInstance.toString(), {}, replace);
}

export function changeUrlQueryParams2(key, value, replace) {
    const urlInstance = new URL(window.location.href);
    const searchParams = urlInstance.searchParams;
    searchParams.set(key, value);
    changeBrowserUrl(toString(urlInstance), {}, replace);
}

export function clearCookies() {
    const jsCode = "\n        const cookies = document.cookie.split(\";\");\n        for (const cookie of cookies) {\n            const eqPos = cookie.indexOf(\"=\");\n            const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;\n            document.cookie = name + \"=;expires=Thu, 01 Jan 1970 00:00:00 GMT\";\n        }\n    ";
    return window.eval(jsCode);
}

export function clearLocalStorage() {
    return window.localStorage.clear();
}

export function clearSessionStorage() {
    return window.sessionStorage.clear();
}

export function clearAllStorage() {
    clearCookies();
    clearLocalStorage();
    return clearSessionStorage();
}

