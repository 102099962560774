import { Remoting_buildProxy_64DC51C } from "./fable_modules/Fable.Remoting.Client.7.32.0/Remoting.fs.js";
import { RemotingModule_createApi, RemotingModule_withRouteBuilder } from "./fable_modules/Fable.Remoting.Client.7.32.0/Remoting.fs.js";
import { Authentication_$reflection, Route_builder } from "./Domain/API.js";
import { startImmediate } from "./fable_modules/fable-library-js.4.20.0/Async.js";
import { singleton } from "./fable_modules/fable-library-js.4.20.0/AsyncBuilder.js";
import { fmt, printf, toText } from "./fable_modules/fable-library-js.4.20.0/String.js";
import { update, init, Msg } from "./MVU/Login.js";
import { Authentication_VerificationCode_TryCreate_Z721C83C5, Authentication_Email_TryCreate_Z721C83C5, Authentication_Email__get_Value } from "./Domain/Model.js";
import { defaultArg, value as value_4, some } from "./fable_modules/fable-library-js.4.20.0/Option.js";
import { ProxyRequestException__get_ResponseText, ProxyRequestException } from "./fable_modules/Fable.Remoting.Client.7.32.0/Types.fs.js";
import { uncurry2, defaultOf } from "./fable_modules/fable-library-js.4.20.0/Util.js";
import { Prop_Of_Z4A92C983, LitElementAttribute, Lit_LitElement__LitElement_dispatchCustomEvent_1EA4E0D4 } from "./fable_modules/Fable.Lit.1.4.2/LitElement.fs.js";
import { cleanDSL, changed, clearAllStorage } from "./ViewFunctions.js";
import { Hook_createDisposable_3A5B6456, HookComponentAttribute } from "./fable_modules/Fable.Lit.1.4.2/Hook.fs.js";
import { Lit_HookContext__HookContext_useEffectOnChange_31A5188A, Hook_get_emptyDisposable, Hook_getContext_343DAFF1, Hook_useHmr_ZF11B4D8 } from "./fable_modules/Fable.Lit.1.4.2/Hook.fs.js";
import { Lit_get_nothing, Lit_refValue_Z4DF72B44, LitHelpers_html } from "./fable_modules/Fable.Lit.1.4.2/Lit.fs.js";
import { FSharpResult$2 } from "./fable_modules/fable-library-js.4.20.0/Result.js";
import { Program_mkHiddenProgramWithOrderExecute } from "./ElmishOrder.js";
import { LitElmishExtensions_useElmish } from "./fable_modules/Fable.Lit.Elmish.1.4.0/Lit.Elmish.fs.js";

const hmr = undefined;

export const Server_api = Remoting_buildProxy_64DC51C(RemotingModule_withRouteBuilder(Route_builder, RemotingModule_createApi()), Authentication_$reflection());

export function execute(host, order, dispatch) {
    switch (order.tag) {
        case 1: {
            startImmediate(singleton.Delay(() => singleton.Bind(Server_api.Login(order.fields[0], ""), (_arg) => {
                const result = _arg;
                if (result.tag === 1) {
                    dispatch(new Msg(5, [toText(printf("%A"))(result.fields[0])]));
                    return singleton.Zero();
                }
                else {
                    dispatch(new Msg(8, []));
                    return singleton.Zero();
                }
            })));
            break;
        }
        case 2: {
            const email_1 = order.fields[0];
            startImmediate(singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(Server_api.Verify([email_1, order.fields[1]]), (_arg_1) => {
                if (_arg_1.tag === 1) {
                    dispatch(new Msg(6, []));
                    return singleton.Zero();
                }
                else {
                    return singleton.Combine(singleton.TryWith(singleton.Delay(() => {
                        let name;
                        Tawk_API.setAttributes((name = Authentication_Email__get_Value(email_1), {
                            email: Authentication_Email__get_Value(email_1),
                            name: name,
                        }));
                        return singleton.Zero();
                    }), (_arg_2) => {
                        console.error(some(_arg_2));
                        return singleton.Zero();
                    }), singleton.Delay(() => {
                        dispatch(new Msg(7, []));
                        return singleton.Zero();
                    }));
                }
            })), (_arg_3) => {
                if (_arg_3 instanceof ProxyRequestException) {
                    const ex_1 = _arg_3;
                    navigator.sendBeacon("/log-error", ProxyRequestException__get_ResponseText(ex_1));
                    window.showError(ProxyRequestException__get_ResponseText(ex_1));
                    return singleton.Zero();
                }
                else {
                    throw _arg_3;
                    return defaultOf();
                }
            })));
            break;
        }
        case 3: {
            startImmediate(singleton.Delay(() => singleton.Bind(Server_api.Logout(order.fields[0]), (_arg_4) => {
                if (_arg_4.tag === 1) {
                    dispatch(new Msg(11, []));
                    return singleton.Zero();
                }
                else {
                    Lit_LitElement__LitElement_dispatchCustomEvent_1EA4E0D4(host, "loggedOut", undefined, true, true);
                    clearAllStorage();
                    window.location.href = "/";
                    dispatch(new Msg(10, []));
                    return singleton.Zero();
                }
            })));
            break;
        }
        case 0: {
            break;
        }
        default: {
            const ex = order.fields[0];
            navigator.sendBeacon("/log-error", ex);
            window.showError(ex);
        }
    }
}

export const view = (new HookComponentAttribute()).Decorate(function (host, model, dispatch) {
    Hook_useHmr_ZF11B4D8(hmr, this);
    const emailRef = Hook_getContext_343DAFF1(this).useRef(() => undefined);
    const dialogRef = Hook_getContext_343DAFF1(this).useRef(() => undefined);
    Hook_getContext_343DAFF1(this).useEffectOnce(() => {
        try {
            const window$ = window.top;
            const matchValue = window$.location.search;
            if (((matchValue.indexOf("?g=") === 0) && (model.Email == null)) && model.FirstRun) {
                dispatch(new Msg(0, []));
            }
        }
        catch (e) {
            console.error(some(e));
        }
        return Hook_get_emptyDisposable();
    });
    Hook_getContext_343DAFF1(this).useEffectOnce(() => {
        const loginRequested = (e_1) => {
            dispatch(new Msg(0, []));
        };
        document.addEventListener("loginRequested", loginRequested);
        return Hook_createDisposable_3A5B6456(() => {
            document.removeEventListener("loginRequested", loginRequested);
        });
    });
    Lit_HookContext__HookContext_useEffectOnChange_31A5188A(Hook_getContext_343DAFF1(this), model.State, (state) => {
        const dialog = value_4(dialogRef.contents);
        switch (state.tag) {
            case 2: {
                dialog.querySelector("input").focus();
                break;
            }
            case 3: {
                dialog.querySelector("button.continue").focus();
                break;
            }
            case 0: {
                if (!model.FirstRun) {
                    dialog.close();
                    host.shadowRoot.querySelector("button").focus();
                }
                break;
            }
            case 4: {
                if (!model.FirstRun) {
                    dialog.close();
                    Lit_LitElement__LitElement_dispatchCustomEvent_1EA4E0D4(host, "loggedIn", value_4(model.Email), true, true);
                }
                break;
            }
            default: {
                if (!dialog.open) {
                    dialog.addEventListener("close", (_arg) => {
                        document.body.focus();
                        dispatch(new Msg(3, []));
                    });
                    dialog.showModal();
                }
                value_4(emailRef.contents).focus();
            }
        }
    });
    const button = (model.State.tag === 4) ? LitHelpers_html(fmt`
                <button class=deny ?disabled=${model.IsBusy} @click=${(_arg_4) => {
        dispatch(new Msg(9, []));
    }}>Sign out</button>
            `) : LitHelpers_html(fmt`<button @click=${(_arg_5) => {
        dispatch(new Msg(0, []));
    }}>Sign in</button>`);
    let dialog_1;
    const matchValue_2 = model.State;
    switch (matchValue_2.tag) {
        case 0:
        case 1:
        case 2:
        case 3: {
            const matchValue_3 = model.State;
            switch (matchValue_3.tag) {
                case 1:
                case 0: {
                    const button_1 = model.IsBusy ? LitHelpers_html(fmt`
                                <div class=spinner>
                                    <div class="opposites">
                                        <div class="opposites bl"></div>
                                        <div class="opposites tr"></div>
                                        <div class="opposites br"></div>
                                        <div class="opposites tl"></div>
                                    </div>
                                </div>
                            `) : LitHelpers_html(fmt`
                                <button ?disabled=${model.IsBusy} aria-live="polite" type=submit>
                                    ${model.IsBusy ? "Please wait" : "Sign in Or Sign up"} 
                                </button>
                            `);
                    dialog_1 = LitHelpers_html(fmt`
                        <br>
                        <div>
                            <slot name=google></slot>
                        </div>
                       
                        <form method="post" action="signin-ms">
                            <button class='msft signin'> <img src="/assets/ms_signin_light.svg"> </button>
                        </form> 
                        <p>or</p>
                        <form @submit='${(e_2) => {
                        let result_2, input_2;
                        e_2.preventDefault();
                        const form = e_2.currentTarget;
                        const formData = new FormData(form);
                        dispatch(new Msg(1, [(result_2 = ((input_2 = Authentication_Email_TryCreate_Z721C83C5(formData.get("email")), (input_2.tag === 1) ? (new FSharpResult$2(1, [input_2.fields[0]])) : (new FSharpResult$2(0, [input_2.fields[0]])))), (result_2.tag === 1) ? (() => {
                            throw new Error("invalid email");
                        })() : result_2.fields[0])]));
                    }}'>
                            <input autofocus ?disabled=${model.IsBusy} type="email" 
                                required name=email ${Lit_refValue_Z4DF72B44(emailRef)} placeholder="Your Work Email"/>
                        <p class=example> e.g. 
                            <strong>john.smith@mycompany.com</strong><br/> 
                        </p>
                            ${button_1}
                           
                        </form>

                    `);
                    break;
                }
                case 2: {
                    dialog_1 = LitHelpers_html(fmt`
                        <p> Enter the verification code sent to your email address. </p>
                        <p> Check your spam box too. </p>

                        <form ?disabled=${model.IsBusy} @submit='${(e_5) => {
                        let result_6;
                        e_5.preventDefault();
                        const form_1 = e_5.currentTarget;
                        const formData_1 = new FormData(form_1);
                        dispatch(new Msg(2, [(result_6 = Authentication_VerificationCode_TryCreate_Z721C83C5(formData_1.get("code")), (result_6.tag === 1) ? (() => {
                            throw new Error("invalid code");
                        })() : result_6.fields[0])]));
                    }}'>
                            <input autofocus ?disabled=${model.IsBusy} type="text" 
                                required name=code inputmode="numeric" pattern="\\d*" autocomplete="off" required placeholder="Verification code"/>
                            <button ?disabled=${model.IsBusy} aria-live="polite" class="verify" type=submit>
                                ${model.IsBusy ? "Please wait" : "Verify"}
                            </button>
                            <button ?disabled=${model.IsBusy} 
                                @click='${(_arg_6) => {
                        dispatch(new Msg(1, [undefined]));
                    }}' type="button">
                                Resend Code
                            </button>
                            <button ?disabled=${model.IsBusy}
                                @click='${(_arg_7) => {
                        dispatch(new Msg(0, []));
                    }}' type="button">
                                Back
                            </button>
                        </form>

                        <p aria-live="assertive">${defaultArg(matchValue_3.fields[0], "")}</p>
                    `);
                    break;
                }
                case 3: {
                    dialog_1 = LitHelpers_html(fmt` 
                        <p> Sign in successful </p>
                        <button  class=continue autofocus @click=${(_arg_8) => {
                        dispatch(new Msg(4, []));
                    }}>Continue</button>
                    `);
                    break;
                }
                default:
                    dialog_1 = Lit_get_nothing();
            }
            break;
        }
        default:
            dialog_1 = Lit_get_nothing();
    }
    return LitHelpers_html(fmt`
        <span>${button}</span>
        <dialog ${Lit_refValue_Z4DF72B44(dialogRef)}>
            <button type=button class="close deny" @click=${(_arg_9) => {
        dispatch(new Msg(3, []));
    }}>
                <img src="/assets/icons/icons8-close.svg" alt='close button'>
            </button>
            <h2> Sign in or Sign up </h2>

             <div>${dialog_1} </div>
        </dialog>
    `);
});

export const InputForm = (new LitElementAttribute("bfb-login")).Decorate(function () {
    let user;
    Hook_useHmr_ZF11B4D8(hmr, this);
    const patternInput = (this).init((arg) => {
        let a;
        const config = arg;
        config.useShadowDom = true;
        a = (config.props = {
            user: Prop_Of_Z4A92C983("", "user", uncurry2(changed), undefined, (x) => x, true),
        });
        return Promise.resolve(undefined);
    });
    const props = patternInput[1];
    const host = patternInput[0];
    cleanDSL(host);
    const program = Program_mkHiddenProgramWithOrderExecute((user = (props.user), () => init(user, undefined)), update, (order, dispatch) => {
        execute(host, order, dispatch);
    });
    const patternInput_1 = LitElmishExtensions_useElmish(Hook_getContext_343DAFF1(this), () => program);
    const model_1 = patternInput_1[0];
    if (model_1.Email == null) {
        props.user = "";
        host.removeAttribute("user");
    }
    return view(host, model_1, patternInput_1[1]);
});

export function register() {
}

