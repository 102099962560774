import { Remoting_buildProxy_64DC51C } from "./fable_modules/Fable.Remoting.Client.7.32.0/Remoting.fs.js";
import { RemotingModule_createApi, RemotingModule_withRouteBuilder } from "./fable_modules/Fable.Remoting.Client.7.32.0/Remoting.fs.js";
import { Credit_$reflection, Route_builder } from "./Domain/API.js";
import { singleton } from "./fable_modules/fable-library-js.4.20.0/AsyncBuilder.js";
import { retry } from "./ViewFunctions.js";
import { startImmediate } from "./fable_modules/fable-library-js.4.20.0/Async.js";
import { dispose, update as update_1, init as init_1, Msg } from "./MVU/Store.js";
import { Program_mkStoreWithOrderExecute, Program_mkHiddenProgramWithOrderExecute } from "./ElmishOrder.js";
import { ProgramModule_onError, ProgramModule_update, ProgramModule_init } from "./fable_modules/Fable.Elmish.4.2.0/program.fs.js";
import { iterate } from "./fable_modules/fable-library-js.4.20.0/List.js";
import { printf, toText } from "./fable_modules/fable-library-js.4.20.0/String.js";
import { uncurry2 } from "./fable_modules/fable-library-js.4.20.0/Util.js";

export const Server_creditAPI = Remoting_buildProxy_64DC51C(RemotingModule_withRouteBuilder(Route_builder, RemotingModule_createApi()), Credit_$reflection());

export function Server_getSubscription(userId, version) {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(retry(() => Server_creditAPI.GetSubscription(userId, version)), (_arg) => singleton.Return(_arg))), (_arg_1) => singleton.Return(undefined)));
}

export function execute(order, dispatch) {
    if (order.tag === 1) {
        startImmediate(singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(retry(() => Server_creditAPI.GetCredit(order.fields[0], order.fields[1])), (_arg) => {
            const res = _arg;
            if (res == null) {
                return singleton.Zero();
            }
            else {
                dispatch(new Msg(0, [res]));
                return singleton.Zero();
            }
        })), (_arg_1) => {
            dispatch(new Msg(2, [_arg_1]));
            return singleton.Zero();
        })));
    }
}

export const program = Program_mkHiddenProgramWithOrderExecute(init_1, update_1, (order, dispatch) => {
    execute(order, dispatch);
});

export const init = ProgramModule_init(program);

export const update = ProgramModule_update(program);

export const error = ProgramModule_onError(program);

export function execute2(order, dispatch) {
    iterate((call) => {
        try {
            call(dispatch);
        }
        catch (ex) {
            error([toText(printf("Error :")), ex]);
        }
    }, order);
}

export const patternInput$004058 = Program_mkStoreWithOrderExecute(init, uncurry2(update), (arg00$0040) => {
    dispose(arg00$0040);
}, (order, dispatch) => {
    execute2(order, dispatch);
})();

export const store = patternInput$004058[0];

export const dispatcher = patternInput$004058[1];

