import { Hook_createDisposable_3A5B6456, HookComponentAttribute } from "./fable_modules/Fable.Lit.1.4.2/Hook.fs.js";
import { Lit_HookContext__HookContext_useEffectOnChange_31A5188A, Hook_getContext_343DAFF1, Hook_useHmr_ZF11B4D8 } from "./fable_modules/Fable.Lit.1.4.2/Hook.fs.js";
import { update, init as init_1, Msg } from "./MVU/Canvas.js";
import { runAnimationOuter, renderResult, init } from "./CanvasRenderer.js";
import { Calculation_Dim__get_Value, Calculation_Container, Calculation_Weight_TryCreate_Z524259A4 } from "./Domain/Model.js";
import { printf, toText, fmt, split, join } from "./fable_modules/fable-library-js.4.20.0/String.js";
import { map } from "./fable_modules/fable-library-js.4.20.0/List.js";
import { toString } from "./fable_modules/fable-library-js.4.20.0/Types.js";
import { value as value_5 } from "./fable_modules/fable-library-js.4.20.0/Option.js";
import { item as item_2 } from "./fable_modules/fable-library-js.4.20.0/Array.js";
import { Lit_refValue_Z4DF72B44, LitHelpers_html, Lit_classes_5A743451 } from "./fable_modules/Fable.Lit.1.4.2/Lit.fs.js";
import { toFloat64 } from "./fable_modules/fable-library-js.4.20.0/BigInt.js";
import { unsafeHTML } from "lit/directives/unsafe-html.js";
import { LitElementAttribute } from "./fable_modules/Fable.Lit.1.4.2/LitElement.fs.js";
import { Program_mkHiddenProgramWithOrderExecute } from "./ElmishOrder.js";
import { LitElmishExtensions_useElmish } from "./fable_modules/Fable.Lit.Elmish.1.4.0/Lit.Elmish.fs.js";

const hmr = undefined;

export function execute(order, dispatch) {
}

export const view = (new HookComponentAttribute()).Decorate(function (host, model, dispatch) {
    let arg, arg_1, arg_2, arg_3, arg_4, arg_5;
    Hook_useHmr_ZF11B4D8(hmr, this);
    const colorRef = Hook_getContext_343DAFF1(this).useRef(() => undefined);
    Hook_getContext_343DAFF1(this).useEffectOnce(() => {
        const viewHandler = (e) => {
            const customEvent = e;
            const canvas = host.querySelector("#my-canvas");
            const patternInput = customEvent.detail;
            if (window.location.href.indexOf("?g=") >= 0) {
                window.setTimeout(() => (canvas.scrollIntoViewIfNeeded()), 0);
            }
            dispatch(new Msg(0, [patternInput[0], patternInput[1], patternInput[2]]));
        };
        const calculating = (e_1) => {
            dispatch(new Msg(1, []));
        };
        const loggedOut = (e_2) => {
            dispatch(new Msg(1, []));
        };
        const canvasItemSelected = (e_3) => {
            const customEvent_1 = e_3;
            dispatch(new Msg(2, [customEvent_1.detail]));
        };
        document.addEventListener("errorOccured", loggedOut);
        document.addEventListener("loggedOut", loggedOut);
        document.addEventListener("viewIn3d", viewHandler);
        document.addEventListener("Calculating", calculating);
        document.addEventListener("canvasItemSelected", canvasItemSelected);
        init(host);
        return Hook_createDisposable_3A5B6456(() => {
            document.removeEventListener("viewIn3d", viewHandler);
            document.removeEventListener("Calculating", calculating);
            document.removeEventListener("loggedOut", loggedOut);
            document.removeEventListener("errorOccured", loggedOut);
            document.removeEventListener("canvasItemSelected", canvasItemSelected);
        });
    });
    Lit_HookContext__HookContext_useEffectOnChange_31A5188A(Hook_getContext_343DAFF1(this), model.OutputRow, (outputRow) => {
        let e_5;
        if (outputRow == null) {
        }
        else {
            const outputRow_1 = outputRow;
            renderResult(new Calculation_Container(outputRow_1.Container.LWH, (e_5 = Calculation_Weight_TryCreate_Z524259A4(0), (e_5.tag === 1) ? (() => {
                throw new Error(join(", ", map((x_2) => {
                    let copyOfStruct = x_2;
                    return toString(copyOfStruct);
                }, e_5.fields[0])));
            })() : e_5.fields[0])), outputRow_1.ItemsPut, false);
        }
    });
    Lit_HookContext__HookContext_useEffectOnChange_31A5188A(Hook_getContext_343DAFF1(this), model.SelectedItem, (selectedItem) => {
        if (selectedItem == null) {
        }
        else {
            const selectedItem_1 = selectedItem;
            value_5(colorRef.contents).style.backgroundColor = item_2(0, split(selectedItem_1.Item.Tag, ["?"], undefined, 0));
        }
    });
    const classes = Lit_classes_5A743451([["noresults", model.OutputRow == null]]);
    let selectedItem_2;
    const matchValue = model.SelectedItem;
    if (matchValue != null) {
        const item_1 = matchValue;
        const name = item_2(1, split(item_1.Item.Tag, ["?"], undefined, 0));
        const name_1 = (name.indexOf("~") >= 0) ? item_2(0, split(name, ["~"], undefined, 0)) : name;
        selectedItem_2 = LitHelpers_html(fmt`
                <p>Item: <span ${Lit_refValue_Z4DF72B44(colorRef)}> &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; </span>&nbsp;  ${name_1}</p> 
                <p>Dims:&nbsp; <span class=number>
                ${(arg = (toFloat64(Calculation_Dim__get_Value(item_1.Item.LWH.Length)) / toFloat64(value_5(model.Muliplier))), (arg_1 = (toFloat64(Calculation_Dim__get_Value(item_1.Item.LWH.Width)) / toFloat64(value_5(model.Muliplier))), (arg_2 = (toFloat64(Calculation_Dim__get_Value(item_1.Item.LWH.Height)) / toFloat64(value_5(model.Muliplier))), toText(printf("L:%A W:%A H:%A"))(arg)(arg_1)(arg_2))))} </span></p> 
                <p>Coords:&nbsp; <span class=number>
                    ${(arg_3 = (toFloat64(item_1.Coordinates.X) / toFloat64(value_5(model.Muliplier))), (arg_4 = (toFloat64(item_1.Coordinates.Y) / toFloat64(value_5(model.Muliplier))), (arg_5 = (toFloat64(item_1.Coordinates.Z) / toFloat64(value_5(model.Muliplier))), toText(printf("X:%A Y:%A Z:%A"))(arg_3)(arg_4)(arg_5))))} </span></p> 
            `);
    }
    else {
        selectedItem_2 = LitHelpers_html(fmt`<p> Click an item from 3D  canvas to <br/> see details</p>`);
    }
    const f = "\n            <style nonce=\"110888888\">\n     \n                #my-canvas {\n                    inline-size: 100% !important;\n                    block-size: 100% !important;\n                    margin-top:1rem;\n                }\n                .dg ul {\n                    font-size: 0.9rem;\n                }\n                .dg input{\n                    margin-block:1px !important;\n                    padding-block:0 !important;\n                }\n                .dg.main{\n                    width: 345px !important;\n                }\n                .noresults {\n                    visibility: collapse;\n                }\n                .selected-canvas-item{\n                    min-block-size:160px;\n                    min-inline-size:40%;\n                }\n            </style>";
    return LitHelpers_html(fmt`
        ${unsafeHTML(f)}
        <fieldset class="${classes}">
            <legend>Canvas</legend>
            <div class="info-panel">
                <div>
                <span class="vfilter">
                <div  aria-label="This is a visual filter for 3D" id="visual-filter"></div>
                    <tool-tip inert role="tooltip" tip-position="top">
                        "This is a visual filter for 3D. h_filter will filter the items horizontally, v_filter will filter the items vertically."
                    </tool-tip>
                </span>
                <br/>  
                <button @click=${(_arg) => {
        runAnimationOuter();
    }}> Play Loading Animation </button>
                </div>

                <fieldset class="selected-canvas-item">
                    <legend> Selected Item </legend>
                    ${selectedItem_2}
                </fieldset>
            </div>

            <canvas  id="my-canvas">A 3D view of the selected for the items in container row</canvas>
        </fieldset>
    `);
});

export const InputForm = (new LitElementAttribute("bfb-canvas")).Decorate(function () {
    Hook_useHmr_ZF11B4D8(hmr, this);
    const patternInput = (this).init((arg) => {
        let a;
        a = (arg.useShadowDom = false);
        return Promise.resolve(undefined);
    });
    const program = Program_mkHiddenProgramWithOrderExecute(init_1, update, (order, dispatch) => {
        execute(order, dispatch);
    });
    const patternInput_1 = LitElmishExtensions_useElmish(Hook_getContext_343DAFF1(this), () => program);
    return view(patternInput[0], patternInput_1[0], patternInput_1[1]);
});

export function register() {
}

