import { Union, Record } from "../fable_modules/fable-library-js.4.20.0/Types.js";
import { union_type, record_type, option_type, string_type, bool_type } from "../fable_modules/fable-library-js.4.20.0/Reflection.js";
import { CreditSystem_Subscription_$reflection, Authentication_Email_$reflection } from "../Domain/Model.js";

export class Model extends Record {
    constructor(BuyCreditDetails, Show, Subscriber, Msg, UserId) {
        super();
        this.BuyCreditDetails = BuyCreditDetails;
        this.Show = Show;
        this.Subscriber = Subscriber;
        this.Msg = Msg;
        this.UserId = UserId;
    }
}

export function Model_$reflection() {
    return record_type("BestFitBox.MVU.BuyCredits.Model", [], Model, () => [["BuyCreditDetails", bool_type], ["Show", bool_type], ["Subscriber", bool_type], ["Msg", option_type(string_type)], ["UserId", option_type(Authentication_Email_$reflection())]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["BuyCreditsRequested", "CancelBuyCredits", "UserLoggedOut", "UserLoggedIn", "BuyCreditsClosed", "SubscriptionResultFetched"];
    }
}

export function Msg_$reflection() {
    return union_type("BestFitBox.MVU.BuyCredits.Msg", [], Msg, () => [[["Item", option_type(string_type)]], [], [], [["Item", Authentication_Email_$reflection()]], [], [["Item", option_type(CreditSystem_Subscription_$reflection())]]]);
}

export class Order extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["None", "GetSubscription", "RequestLogin"];
    }
}

export function Order_$reflection() {
    return union_type("BestFitBox.MVU.BuyCredits.Order", [], Order, () => [[], [["Item", Authentication_Email_$reflection()]], []]);
}

export function init(show, subscriber, unitVar) {
    return [new Model(false, show, subscriber, undefined, undefined), new Order(0, [])];
}

export function update(msg, model) {
    switch (msg.tag) {
        case 0:
            if (!model.Show) {
                return [model, new Order(2, [])];
            }
            else {
                return [new Model(true, model.Show, model.Subscriber, msg.fields[0], model.UserId), new Order(0, [])];
            }
        case 2:
            return [new Model(model.BuyCreditDetails, false, model.Subscriber, model.Msg, model.UserId), new Order(0, [])];
        case 3: {
            const userId = msg.fields[0];
            return [new Model(model.BuyCreditDetails, true, model.Subscriber, model.Msg, userId), new Order(1, [userId])];
        }
        case 4:
            return [new Model(false, model.Show, model.Subscriber, model.Msg, model.UserId), new Order(0, [])];
        case 5:
            if (msg.fields[0] == null) {
                return [new Model(model.BuyCreditDetails, model.Show, false, model.Msg, model.UserId), new Order(0, [])];
            }
            else {
                return [new Model(model.BuyCreditDetails, model.Show, true, model.Msg, model.UserId), new Order(0, [])];
            }
        default:
            return [new Model(false, model.Show, model.Subscriber, model.Msg, model.UserId), new Order(0, [])];
    }
}

